import React, { useEffect,useState,useMemo } from 'react';
import '../../src/Styles.css';
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import couple from '../assets/screenshot_large_3.jpg';
import up from '../assets/up.png'
import HomeDrawer from '../Components/HomeDrawer';

const Blog_1 = () => {

        useEffect(() => {
          window.scrollTo(0, 0)
        }, [])

    const [themeTextColor, setTheme] = useState(null);

    const isDark = undefined;
    // Detecting the default theme
    const systemPrefersDark = useMediaQuery(
      {
        query: "(prefers-color-scheme: dark)",
      },
      undefined
    );
  
  
    const value = useMemo(
      () => (isDark === undefined ? !!systemPrefersDark : isDark),
      [isDark, systemPrefersDark]
    );
  
    useEffect(() => {
      if (value) {
        setTheme('#fff')
      } else {
        setTheme('#111')
      }
    }, [value]);
    
        return (

            <div>

                <div>
                    <HomeDrawer />
                </div>


                <div onClick={() => window.scrollTo(0, 0)} style={{ position: 'fixed', bottom: 10, right: 10, cursor: 'pointer' }}>
                    <img src={up} alt="Trulli" className="up-screen" ></img>
                </div>

                <div className='blog-div-container'>
                    <div className='blog-content'>

                    <Link style={{ textDecoration: 'none' }} className="App-link" to="/">
                    <div className="logo" ><h1 style={{ color: themeTextColor, }}>Caribe Match</h1></div>
                </Link>

                        <h2 className='blog-title-header' style={{ color: themeTextColor}}>10 Astonishing Things That Happen When You Meet Your Soulmate</h2>

                        <div style={{ marginTop: 12 }} >
                            <img src={couple} alt="Trulli" className='blog-image'></img>
                        </div>

                        <h3 className='blog-pargraph-autor' style={{ marginTop: 5, color: themeTextColor }}>By Crystal Aav</h3>

                        <p className='blog-pargraph' style={{ marginTop: 0, color: themeTextColor  }}>They say if you want something in life the whole universe conspires to make it happen.
                            Similar is the case with love. Love is an unfathomable emotion and people desire it the most.
                            Having someone by your side with whom you can open up is an amazing feeling. While the phase
                            of crushes and infatuations are temporary and passes soon, meeting with your true soul mate
                            is something permanent and strong. Soulmates are considered the epitome of love and partnership.
                            In the chaotic world, if you found your soulmate, consider yourself the luckiest person on earth.
                            But in the bustle of every day, how do you figure out that this person is your ultimate soulmate?
                            Do things remain pretty much the same when you see him? You must have seen in movies, heard in
                            songs, and read in novels how the things go upside down when you see your soulmate. If you feel
                            entire, whole, and intact with a person, there could be signs of him being your soulmate.
                            The missing void inside you gets filled and you feel complete. Apart from these if you
                            experience the below-jotted points be ready for the story which ends with a
                            “happily ever after”!


                            <h3 className='blog-pargraph-title1' style={{ padding: 5, marginTop: 10, color: themeTextColor  }}>1. A smile continues to stay on your face</h3>

                            When you meet your love of life, you continually confront dizzy feelings of happiness – even if your soul mate is not around you.
                            Whenever you think about them, a smile comes automatically on your face. People around you can quickly notice you
                            blushing, but you don’t care as if you are not in the present world, but rather in the dream world of your soul mate.

                            <h3 className='blog-pargraph-title1' style={{ padding: 5, color: themeTextColor  }} > 2. The way you see the world changes</h3>

                            When you meet your soul mate, you start to see the world from the positive perspective. Your attitude towards other
                            people changes as you become happier and optimistic once he/she enters your life. Forgiveness and friendliness enter
                            into your dictionary as you would like to share your actual world with everyone around you.

                            <h3 className='blog-pargraph-title1' style={{ padding: 5, color: themeTextColor  }}>3. You become a great problem solver</h3>

                            When someone comes into your life, then your confidence level gradually increases. This strength of yours makes you
                            a great problem solver and allows you to focus more on the things that require special attention. Your soul mate is
                            always there to help you out from the imminent disasters.

                            <h3 className='blog-pargraph-title1' style={{ padding: 5, color: themeTextColor  }}>4. You start to explore different things</h3>

                            Your life changes drastically when that someone special comes in. In regard to praise, your soul mate’s hobbies and
                            interests, you would surely open your door to learn unique and different things, and that will automatically increase
                            your wisdom. You might take a gym class with your soul mate or maybe you will spend 4 hours watching a cricket match
                            just because you want to know more about your love of life.

                            <h3 className='blog-pargraph-title1' style={{ padding: 5, color: themeTextColor  }}>5. You go out of your way for your soul mate</h3>

                            Just to express your extreme love for your soul mate, you never hesitate to go out of your way for your love.
                            Whether it is about cooking late at night for them or picking them up from their office. You feel energized to do
                            anything for their happiness. To people, it sounds like a nuisance, but you don’t care about their words.

                            <h3 className='blog-pargraph-title1' style={{ padding: 5, color: themeTextColor  }}>6. Your soul mate gets you</h3>

                            Once your soul mate comes in, you realize somebody is there who understands you and can spell all your problems.
                            Through the good, the bad, and the ugly, somehow he/she gets you. You won’t be alone anymore in your hard and good
                            times.

                            <h3 className='blog-pargraph-title1' style={{ padding: 5, color: themeTextColor  }}>7. Miracles begin to happen</h3>

                            When you find someone who completes you, your soul mate, it feels like a miracle to you. With your entirely new
                            sense of love, happiness, and fulfillment, you will also begin to encounter other happy things around you. Isn’t it
                            amazing?

                            <h3 className='blog-pargraph-title1' style={{ padding: 5, color: themeTextColor  }}>8. You connect more deeply with friends and relatives</h3>

                            Once your soul mate comes by your side, then you start cherishing your other meaningful connections too.
                            Once you realize the strong bond with your soul mate, then you also appreciate your relationship with other people
                            in your life. You start liking your friends’ and parents’ company, spending long hours discussing their love lives.

                            <h3 className='blog-pargraph-title1' style={{ padding: 5, color: themeTextColor  }}>9. Love happens for other people around you too</h3>

                            Finding the love of your life encourages you to take a step for other people around you in helping them find their
                            true love around them. You feel astonished when you see happy couples around you.

                            <h3 className='blog-pargraph-title1' style={{ padding: 5, color: themeTextColor  }}>10. Your future plans change</h3>

                            All of a sudden your future goals and plans change! Your soul mate’s dreams would be your dreams too. Yes! It’s quite
                            a crazy thing, but yes it happens with people who are in true love with their soul mate. You would eventually start
                            thinking from his/her perspective and then the stage comes when you might realize that your personal goals have
                            changed.

                        </p>
                    </div>
                </div>
            </div>
        );
}


export default Blog_1;
