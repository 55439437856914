import React from 'react'
import '../../src/Styles.css';
import HomeDrawer from '../Components/HomeDrawer';


const PageNotFound = () => {
    return (
        <div >
<div>
          <HomeDrawer />
        </div> 

        <div className='not-found-center'>
          <h3 style={{ fontSize: 150 }}>404</h3>
          <div className='not-found-inline'>
            <h3 style={{textAlign: 'center',fontSize: 50}}>Sorry!</h3>
            <h3 style={{textAlign: 'center',fontSize: 30}}>This page could not be found</h3>
          </div>
        </div>
        <div style={{ position: 'absolute', top: 180, left: 160, borderRadius: 20, backgroundColor: '#a89e9e', height: 20, width: 20 }} />
        <div style={{ position: 'absolute', top: 190, left: 60, borderRadius: 20, backgroundColor: '#a89e9e', height: 10, width: 10 }} />
        <div style={{ position: 'absolute', top: 80, left: 20, borderRadius: 20, backgroundColor: '#a89e9e', height: 20, width: 20 }} />
        <div style={{ position: 'absolute', top: 100, left: 40, borderRadius: 20, backgroundColor: '#a89e9e', height: 20, width: 20 }} />
        <div style={{ position: 'absolute', top: 200, left: 80, borderRadius: 20, backgroundColor: '#a89e9e', height: 20, width: 20 }} />
        <div style={{ position: 'absolute', top: 190, right: 20, borderRadius: 20, backgroundColor: '#a89e9e', height: 20, width: 20 }} />
        <div style={{ position: 'absolute', top: 100, right: 40, borderRadius: 20, backgroundColor: '#a89e9e', height: 10, width: 10 }} />
        <div style={{ position: 'absolute', top: 180, right: 160, borderRadius: 20, backgroundColor: '#a89e9e', height: 20, width: 20 }} />
        <div style={{ position: 'absolute', top: 210, right: 50, borderRadius: 20, backgroundColor: '#a89e9e', height: 10, width: 10 }} />
        <div style={{ position: 'absolute', top: 130, right: 70, borderRadius: 20, backgroundColor: '#a89e9e', height: 20, width: 20 }} />

      </div >
    )
}

export default PageNotFound