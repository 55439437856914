import React, { useEffect,useState,useMemo } from 'react';
import '../../src/Styles.css';
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import up from '../assets/up.png'
import HomeDrawer from '../Components/HomeDrawer';


const PrivacyPolicy = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [themeTextColor, setTheme] = useState(null);

  const isDark = undefined;
  // Detecting the default theme
  const systemPrefersDark = useMediaQuery(
    {
      query: "(prefers-color-scheme: dark)",
    },
    undefined
  );


  const value = useMemo(
    () => (isDark === undefined ? !!systemPrefersDark : isDark),
    [isDark, systemPrefersDark]
  );

  useEffect(() => {
    if (value) {
      setTheme('#fff')
    } else {
      setTheme('#111')
    }
  }, [value]);
  
  return (
    <div className='blog-div-container'>
<div>
          <HomeDrawer />
        </div> 

    <div className='blog-content'>

    <div onClick={() =>  window.scrollTo(0, 0) } style={{position:'fixed',bottom:10,  right:10, cursor: 'pointer'}}>    
     <img src={up} alt="Trulli"  className="up-screen" ></img>
      </div>

      <Link style={{ textDecoration: 'none' }} className="App-link" to="/">
      <div className="logo" ><h1 style={{ color: themeTextColor, }}>Caribe Match</h1></div>
      </Link>


      <h2 className='blog-pargraph-title' style={{ color: themeTextColor}}>Privacy Policy</h2>

        <div>

          <h2 className='blog-pargraph-date' style={{ color: themeTextColor}}>Last revised on June 30, 2024</h2>

          <p className='blog-pargraph' style={{ color: themeTextColor}}>
           Welcome to Caribe Match Privacy Policy. Thank you for taking the time to read it.

We appreciate that you trust us with your information and we intend to always keep that trust. This starts with making sure you understand the information we collect, why we collect it, how it is used and your choices regarding your information. This Policy describes our privacy practices in plain language, keeping legal and technical jargon to a minimum.
               </p>

          <p className='blog-pargraph' style={{ color: themeTextColor}}>
          At Caribe Match, your privacy is a top priority. Your privacy is at the core of the way we design and build the services and products you know and love, so that you can fully trust them and focus on building meaningful connections.

We appreciate that you put your trust in us when you provide us with your information and we do not take this lightly.
          </p>

          <p className='blog-pargraph' style={{ color: themeTextColor}}>
          We do not compromise with your privacy. We design all of our products and services with your privacy in mind. We involve experts from various fields, including legal, security, engineering, product design and others to make sure that no decision is taken without respect for your privacy.
           </p>

               <p className='blog-pargraph' style={{ color: themeTextColor}}>
               We strive to be transparent in the way we process your data. Because we use many of the same online services you do, we know that insufficient information and overly complicated language are common issues in privacy policies. We take the exact opposite approach: we have written our Privacy Policy and related documents in plain language. We actually want you to read our policies and understand our privacy practices!
               </p>

               <p className='blog-pargraph' style={{ color: themeTextColor}}>
               We work hard to keep your information secure We have teams dedicated to keeping your data safe and secure. We constantly update our security practices and invest in our security efforts to enhance the safety of your information.
               </p>

               <h2 className='blog-pargraph-sub-title' style={{ color: themeTextColor}}>WHERE THIS PRIVACY POLICY APPLIES</h2>
               <p className='blog-pargraph'style={{ color: themeTextColor}}>
               This Privacy Policy applies to websites, apps, events and other services operated by Yana Plus LLC. For simplicity, we refer to all of these as our “services” in this Privacy Policy. To make it extra clear, we’ve added links to this Privacy Policy on all applicable services.

Some services may require their own unique privacy policy. If a particular service has its own privacy policy, then that policy -- not this Privacy Policy -- applies.
               </p>

               <h2 className='blog-pargraph-sub-title'style={{ color: themeTextColor}}>INFORMATION WE COLLECT</h2>
               <p className='blog-pargraph' style={{ color: themeTextColor}}>
               It goes without saying, we can’t help you develop meaningful connections without some information about you, such as basic profile details and the types of people you’d like to meet. We also collect information generated as you use our services, for example access logs, as well as information from third parties, like when you access our services through a social media account. If you want additional info, we go into more detail below.
               </p>

               <h2 className='blog-pargraph-sub-title' style={{ color: themeTextColor}}>Information you give us</h2>
               <p className='blog-pargraph' style={{ color: themeTextColor}}>
               You choose to give us certain information when using our services. This includes:

When you create an account, you provide us with at least your login credentials, as well as some basic details necessary for the service to work, such as your gender and date of birth.
When you complete your profile, you can share with us additional information, such as details on your personality, lifestyle, interests and other details about you, as well as content such as photos and videos. To add certain content, like pictures or videos, you may allow us to access your camera or photo album. Some of the information you choose to provide us may be considered “special” or “sensitive” in certain jurisdictions, for example your racial or ethnic origins, sexual orientation and religious beliefs. By choosing to provide this information, you consent to our processing of that information.
When you subscribe to a paid service or make a purchase directly from us (rather than through a platform such as iOS or Android), you provide us or our payment service provider with information, such as your debit or credit card number or other financial information.
When you participate in surveys or focus groups, you give us your insights into our products and services, responses to our questions and testimonials.
When you choose to participate in our promotions, events or contests, we collect the information that you use to register or enter.
If you contact our customer care team, we collect the information you give us during the interaction. Sometimes, we monitor or record these interactions for training purposes and to ensure a high quality of service.
If you ask us to communicate with or otherwise process information of other people (for example, if you ask us to send an email on your behalf to one of your friends), we collect the information about others that you give us in order to complete your request.
Of course, we also process your chats with other users as well as the content you publish, as part of the operation of the services.
               </p>

               <h2 className='blog-pargraph-sub-title' style={{ color: themeTextColor}}>Information we receive from others</h2>
               <p className='blog-pargraph' style={{ color: themeTextColor}}>
               You choose to give us certain information when using our services. This includes:
               </p>

               <li className='blog-pargraph' style={{ color: themeTextColor}}>When you create an account, you provide us with at least your login credentials, as well as some basic details necessary for the service to work, such as your gender and date of birth.</li>
               <li className='blog-pargraph' style={{ color: themeTextColor}}>When you complete your profile, you can share with us additional information, such as details on your personality,
                  lifestyle, interests and other details about you, as well as content such as photos and videos. To add certain content, like pictures or videos, you may allow us to access your camera or photo album. Some of the information you choose to provide us may be considered “special” or “sensitive” in certain jurisdictions, for example your racial or ethnic origins, sexual orientation and religious beliefs.
                  By choosing to provide this information, you consent to our processing of that information.</li>
               <li className='blog-pargraph' style={{ color: themeTextColor}}>When you participate in surveys or focus groups, you give us your insights into our products and services,
                responses to our questions and testimonials.</li>
               <li className='blog-pargraph' style={{ color: themeTextColor}}>When you choose to participate in our promotions, events or contests, we collect the information that you use to register or enter.
</li>
               <li className='blog-pargraph' style={{ color: themeTextColor}}>If you contact our customer care team, we collect the information you give us during the interaction. Sometimes, we monitor or record these interactions for training purposes and to ensure a high quality of service.
</li>
               <li className='blog-pargraph' style={{ color: themeTextColor}}>If you ask us to communicate with or otherwise process information of other people (for example, if you ask us to send an email on your behalf to one of your friends),
                we collect the information about others that you give us in order to complete your request.</li>
               <li className='blog-pargraph' style={{ color: themeTextColor}}>Of course, we also process your chats with other users as well as the content you publish,
                as part of the operation of the services.</li>
             
               <h2 className='blog-pargraph-sub-title' style={{ color: themeTextColor}}>Information we receive from others
</h2>
               <p className='blog-pargraph' style={{ color: themeTextColor}}>
               In addition to the information you provide us directly, we receive information about you from others, including:
               </p>

               <li className='blog-pargraph' style={{ color: themeTextColor}}>Other Users</li>
               <p className='blog-pargraph' style={{ color: themeTextColor}}>
               Other users may provide information about you as they use our services. For instance,
                we may collect information about you from other users if they contact us about you.
               </p>

               <li className='blog-pargraph' style={{ color: themeTextColor}}>Social Media</li>
               <p className='blog-pargraph' style={{ color: themeTextColor}}>
               You may be able to use your social media login (such as Facebook Login) to create and log into your Caribe Match account. This saves you from having to remember yet another user name
                and password and allows you to share some information from your social media account with us.
               </p>
               
               <li className='blog-pargraph' style={{ color: themeTextColor}}>Other Partners</li>
               <p className='blog-pargraph' style={{ color: themeTextColor}}>
               We may receive info about you from our partners, for instance where Caribe Match ads are published on a partner’s
                websites and platforms (in which case they may pass along details on a campaign’s success).
               </p>

               <h2 className='blog-pargraph-sub-title' style={{ color: themeTextColor}}>Information collected when you use our services</h2>
               <p className='blog-pargraph' style={{ color: themeTextColor}}>
               When you use our services, we collect information about which features you’ve used, how you’ve used
                them and the devices you use to access our services. See below for more details:
               </p>

               <li className='blog-pargraph' style={{ color: themeTextColor}}>Usage Information</li>
               <p className='blog-pargraph' style={{ color: themeTextColor}}>
               We collect information about your activity on our services, for instance how you use them (e.g., date and time you logged in, features you’ve been using, searches, clicks and pages which have been shown to you, referring webpage address, advertising that you click on) and how you interact with other users
                (e.g., users you connect and interact with, time and date of your exchanges, number of messages you send and receive).
               </p>

               <li className='blog-pargraph' style={{ color: themeTextColor}}>Device information</li>
               <p className='blog-pargraph' style={{ color: themeTextColor}}>
               We collect information from and about the device(s) you use to access our services, including:
               </p>

               <ul style={{listStyleType: 'square'}}>
  <li className='blog-pargraph' style={{ color: themeTextColor}}>hardware and software information such as IP address, device ID and type, device-specific and apps settings and characteristics, app crashes, advertising IDs (such as Google’s AAID and Apple's IDFA, both of which are randomly generated numbers that you can reset by going into your device’ settings), browser type, version and language, operating system, time zones, identifiers associated with cookies or other technologies that may uniquely identify your device or browser (e.g., IMEI/UDID and MAC address);
</li>
  <li className='blog-pargraph' style={{ color: themeTextColor}}>information on your wireless and mobile network connection, like your service provider and signal strength;</li>
  <li className='blog-pargraph' style={{ color: themeTextColor}}>information on device sensors such as accelerometers, gyroscopes and compasses.</li>
</ul>  

               <li className='blog-pargraph' style={{ color: themeTextColor}}>Other information with your consent</li>
               <p className='blog-pargraph' style={{ color: themeTextColor}}>
               If you give us permission, we can collect your precise geolocation (latitude and longitude) through various means, depending on the service and device you’re using, including GPS, Bluetooth or Wi-Fi connections. The collection of your geolocation may occur in the background even when you aren’t using the services if the permission you gave us expressly permits such collection. If you decline permission for us to collect your geolocation, we will not collect it.
Similarly, if you consent, we may collect your photos and videos (for instance, if you want to publish a photo, video or streaming on the services).
               </p>

               <h2 className='blog-pargraph-sub-title' style={{ color: themeTextColor}}>COOKIES AND OTHER SIMILAR DATA COLLECTION TECHNOLOGIES</h2>
               <p className='blog-pargraph' style={{ color: themeTextColor}}>
               We use and may allow others to use cookies and similar technologies (e.g., web beacons, pixels) to recognize you and/or your device(s). You may read our Cookie Policy for more information on why we use them (such as authenticating you, remembering your preferences and settings, analyzing site traffic and trends, delivering and measuring the effectiveness of advertising campaigns, allowing you to use social features) and how you can better control their use,
                through your browser settings and other tools.
               </p>

               <p className='blog-pargraph' style={{ color: themeTextColor}}>
               Some web browsers (including Safari, Internet Explorer, Firefox and Chrome) have a “Do Not Track” (“DNT”) feature that tells a website that a user does not want to have his or her online activity tracked. If a website that responds to a DNT signal receives a DNT signal, the browser can block that website from collecting certain information about the browser’s user. Not all browsers offer a DNT option and DNT signals are not yet uniform. For this reason,
                many businesses, including Yana Plus LLC, do not currently respond to DNT signals.
               </p>
              
               <p className='blog-pargraph' style={{ color: themeTextColor}}>
               The main reason we use your information is to deliver and improve our services. Additionally, we use your info to help keep you safe and to provide you with advertising that may be of interest to you. Read on for a more detailed explanation of the various reasons we use your information, together with practical example
               </p>

               <h2 className='blog-pargraph-sub-title' style={{ color: themeTextColor}}>To administer your account and provide our services to you</h2>
              
  <li className='blog-pargraph' style={{ color: themeTextColor}}>Create and manage your account</li>
  <li className='blog-pargraph' style={{ color: themeTextColor}}>Provide you with customer support and respond to your requests</li>
  <li className='blog-pargraph' style={{ color: themeTextColor}}>Complete your transactions</li>

               <h2 className='blog-pargraph-sub-title' style={{ color: themeTextColor}}>To help you connect with other users</h2>
               <li className='blog-pargraph' style={{ color: themeTextColor}}>Analyze your profile, activity on the service, and preferences to recommend meaningful
                connections to you and recommend you to others</li>
                <li className='blog-pargraph' style={{ color: themeTextColor}}>Show users’ profiles to one another</li>

               <h2 className='blog-pargraph-sub-title' style={{ color: themeTextColor}}>To ensure a consistent experience across your devices</h2> 
               <li className='blog-pargraph' style={{ color: themeTextColor}}>Link the various devices you use so that you can enjoy a consistent experience of our services on all of them. We do this by linking devices and browser data, such as when you log into your account on different devices or by using partial or full IP address,
                browser version and similar data about your devices to help identify and link them.</li>

                <h2 className='blog-pargraph-sub-title' style={{ color: themeTextColor}}>To provide new Caribe Match services to you</h2>
               <li className='blog-pargraph' style={{ color: themeTextColor}}>Register you and display your profile on new Caribe Match features and apps</li>
               <li className='blog-pargraph' style={{ color: themeTextColor}}>Administer your account on these new features and apps</li>

               <h2 className='blog-pargraph-sub-title' style={{ color: themeTextColor}}>To serve you relevant offers and ads</h2>
               <li className='blog-pargraph' style={{ color: themeTextColor}}>Administer sweepstakes, contests, discounts or other offers</li>
               <li className='blog-pargraph' style={{ color: themeTextColor}}>Develop, display and track content and advertising tailored to your interests on our services and other sites</li>
               <li className='blog-pargraph' style={{ color: themeTextColor}}>Communicate with you by email, phone, social media or mobile device about
                products or services that we think may interest you</li>

               <h2 className='blog-pargraph-sub-title' style={{ color: themeTextColor}}>To improve our services and develop new ones</h2>
               <li className='blog-pargraph' style={{ color: themeTextColor}}>Administer focus groups and surveys</li>
               <li className='blog-pargraph' style={{ color: themeTextColor}}>Conduct research and analysis of users’ behavior to improve our services and content (for instance, we may decide to change the look and feel or even substantially modify
                a given feature based on users’ behavior)</li>
               <li className='blog-pargraph' style={{ color: themeTextColor}}>Develop new features and services (for example, we may decide to build
                a new interests-based feature further to requests received from users).</li>

               <h2 className='blog-pargraph-sub-title' style={{ color: themeTextColor}}>To prevent, detect and fight fraud or other illegal or unauthorized activities</h2>
               <li className='blog-pargraph' style={{ color: themeTextColor}}>Address ongoing or alleged misbehavior on and off-platform</li>
               <li className='blog-pargraph' style={{ color: themeTextColor}}>Perform data analysis to better understand and design countermeasures against these activities</li>
               <li className='blog-pargraph' style={{ color: themeTextColor}}>Retain data related to fraudulent activities to prevent against recurrences</li>

               <h2 className='blog-pargraph-sub-title' style={{ color: themeTextColor}}>To ensure legal compliance</h2>
               <li className='blog-pargraph' style={{ color: themeTextColor}}>Comply with legal requirements</li>
               <li className='blog-pargraph' style={{ color: themeTextColor}}>Assist law enforcement</li>
               <li className='blog-pargraph' style={{ color: themeTextColor}}>Enforce or exercise our rights, for example our <Link style={{ textDecoration: 'none',color:'#2049ff'}} className="App-link" to="/terms-conditions"> terms</Link></li>

               <p className='blog-pargraph' style={{ color: themeTextColor}}>
               To process your information as described above, we rely on the following legal bases:
               </p>

               <ul style={{listStyleType: 'square'}}>
  <li className='blog-pargraph' style={{ color: themeTextColor}}>Provide our service to you: Most of the time, the reason we process your information is to perform the contract that you have with us. For instance, as you go about using our service to build meaningful connections, we use your information
   to maintain your account and your profile, to make it viewable to other users and recommend other users to you.
</li>
  <li className='blog-pargraph' style={{ color: themeTextColor}}>Legitimate interests: We may use your information where we have legitimate interests to do so. For instance, we analyze users’ behavior on our services to continuously improve our offerings, we suggest offers we think might interest you,
   and we process information for administrative, fraud detection and other legal purposes.</li>
  <li className='blog-pargraph' style={{ color: themeTextColor}}>Consent: From time to time, we may ask for your consent to use your information for certain specific reasons. You may withdraw your consent at any time by contacting us at the address provided at the end of this Privacy Policy.</li>
</ul> 

               <h2 className='blog-pargraph-sub-title' style={{ color: themeTextColor}}>HOW WE SHARE INFORMATION</h2>
               <p className='blog-pargraph' style={{ color: themeTextColor}}>
               Since our goal is to help you make meaningful connections, the main sharing of users’ information is, of
                course, with other users. We also share some users’ information with service providers and partners who
                 assist us in operating the services,in some cases,
                legal authorities. Read on for more details about how your information is shared with others.
               </p>

               <li className='blog-pargraph-sub-title' style={{ color: themeTextColor}}>With other users</li>

               <p className='blog-pargraph' style={{ color: themeTextColor}}>You share information with other users when you voluntarily disclose information on the service (including your public profile). Please be careful with your information and make sure that the content you share is stuff that you’re comfortable being publically viewable since neither you nor we can control what others do with your information once you share it.

If you choose to limit the audience for all or part of your profile or for certain content or information about you,
 then it will be visible according to your settings.</p>

<li className='blog-pargraph-sub-title' style={{ color: themeTextColor}}>With our service providers and partners</li>

<p className='blog-pargraph' style={{ color: themeTextColor}}>We use third parties to help us operate and improve our services. These third parties assist us with various tasks, including data hosting and maintenance, analytics, customer care, marketing, advertising, payment processing and security operations.

We may also share information with partners who distribute and assist us in advertising our services. For instance, we may share limited information on you in hashed, non-human readable form to advertising partners.

We follow a strict vetting process prior to engaging any service provider or working with any partner. All of our service providers and partners must agree to strict confidentiality obligations.

</p>

               <li className='blog-pargraph-sub-title' style={{ color: themeTextColor}}>For corporate transactions</li>
               <p className='blog-pargraph' style={{ color: themeTextColor}}>
               We may transfer your information if we are involved, whether in whole or in part, in a merger, sale, acquisition, divestiture, restructuring, reorganization, dissolution,
                bankruptcy or other change of ownership or control.
               </p>

               <li className='blog-pargraph-sub-title' style={{ color: themeTextColor}}>When required by law</li>
               <p className='blog-pargraph' style={{ color: themeTextColor}}>
               We may disclose your information if reasonably necessary: (i) to comply with a legal process, such as a court order, subpoena or search warrant, government / law enforcement investigation or other legal requirements; (ii) to assist in the prevention
                or detection of crime (subject in each case to applicable law); or (iii) to protect the safety of any person.
               </p>

               <li className='blog-pargraph-sub-title' style={{ color: themeTextColor}}>To enforce legal rights</li>
               <p className='blog-pargraph' style={{ color: themeTextColor}}>
               We may also share information: (i) if disclosure would mitigate our liability in an actual or threatened lawsuit; (ii) as necessary to protect our legal rights and legal rights of our users, business partners or other interested parties; (iii) to enforce our agreements with you; and (iv) to investigate, prevent,
                or take other action regarding illegal activity, suspected fraud or other wrongdoing.
               </p>

               <h2 className='blog-pargraph-sub-title' style={{ color: themeTextColor}}>YOUR RIGHTS</h2>
               <p className='blog-pargraph' style={{ color: themeTextColor}}>
               We want you to be in control of your information, so we have provided you with the following tools:
               </p>

               <li className='blog-pargraph' style={{ color: themeTextColor}}>Device permissions. Mobile platforms have permission systems for specific types of
                device data and notifications, such as phone book and location services as well as push notifications. You can
                 change your settings on your device to either consent or oppose the collection of the corresponding information or the display of the corresponding
                notifications. Of course, if you do that, certain services may lose full functionality.</li>

               <li className='blog-pargraph' style={{ color: themeTextColor}}>Deletion. You can delete your account by using the corresponding functionality directly on the service.</li>

               <p className='blog-pargraph' style={{ color: themeTextColor}}>
               We want you to be aware of your privacy rights. Here are a few key points to remember:
               </p>

               <li className='blog-pargraph' style={{ color: themeTextColor}}>Reviewing your information. Applicable privacy laws may give you the right to review the personal information we keep about you (depending on the jurisdiction, this may be called right of access, right of portability or variations of those <Link style={{ textDecoration: 'none',color:'#2049ff'}} className="App-link" to="/terms-conditions"> terms</Link>).
                You can request a copy of your personal information by putting in such a request here.</li>

                <li className='blog-pargraph' style={{ color: themeTextColor}}>Updating your information. If you believe that the information we hold about you
                 is inaccurate or that we are no longer entitled to use it and want to request its rectification,
                  deletion or object to its processing, please contact us
                   <Link style={{ textDecoration: 'none',color:'#2049ff' }} className="App-link" to="/contact-us"> here</Link>.</li>

               <p className='blog-pargraph' style={{ color: themeTextColor}}>
               For your protection and the protection of all of our users, we may ask you to provide proof of identity before we can answer the above requests.
Keep in mind, we may reject requests for certain reasons, including if the request is unlawful or if it may infringe on trade secrets or intellectual property or the privacy of another user. If you wish to receive information relating to another user, such as a copy of any messages you received from him or her through our service, the other user
 will have to contact our Privacy Officer to provide their written consent before the information is released.
Also, we may not be able to accommodate certain requests to object to the processing of personal information, notably where such requests would not allow us to provide our service to you anymore. For instance, we cannot provide our service if we do not have your date of birth.
               </p>

               <h2 className='blog-pargraph-sub-title' style={{ color: themeTextColor}}>HOW WE PROTECT YOUR INFORMATION</h2>
               <p className='blog-pargraph' style={{ color: themeTextColor}}>
               We work hard to protect you from unauthorized access to or alteration, disclosure or destruction of your personal information. As with all technology companies, although we take steps to secure your information, we do not promise, and you should not expect, that your personal information will always remain secure.

We regularly monitor our systems for possible vulnerabilities and attacks and regularly review our information collection, storage and processing practices to update our physical, technical and organizational security measures.

We may suspend your use of all or part of the services without notice if we suspect or detect any breach of security. If you believe that your account or information is no longer secure, please notify us immediately <Link style={{ textDecoration: 'none',color:'#2049ff' }} className="App-link" to="/contact-us"> here</Link>.
 </p>

               <h2 className='blog-pargraph-sub-title' style={{ color: themeTextColor}}>CHILDREN’S PRIVACY</h2>
               <p className='blog-pargraph' style={{ color: themeTextColor}}>Our services are restricted to users who are 18 years of age or older. We do not permit
                users under the age of 18 on our platform and we do not knowingly collect personal information from anyone under the
                 age of 18. If you suspect that a user is under the age of 18, please use the reporting mechanism available through the service.</p>

                 <h2 className='blog-pargraph-sub-title' style={{ color: themeTextColor}}>HOW TO CONTACT US</h2>
            
               <p className='blog-pargraph' style={{ color: themeTextColor}}>If you have questions about this Privacy Policy, you can contact us <Link style={{ textDecoration: 'none',color:'#2049ff' }} className="App-link" to="/contact-us"> here</Link>.</p>

        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
