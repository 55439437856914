import React, { useEffect, useState, useCallback, useMemo } from 'react';
import '../../src/Styles.css';
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import axios from 'axios';
//import instance from '../api/userApi';
//import ReCAPTCHA from "react-google-recaptcha";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha
} from 'react-google-recaptcha-v3';
import '../../src/Styles.css'
import HomeDrawer from '../Components/HomeDrawer'
import Spinner from '../Components/Spinner';


const Contact = () => {


  const [stateEmail, setStateEmail] = useState('')
  const [statePhone, setStatePhone] = useState('')
  const [stateName, setStateName] = useState('')
  const [stateDescription, setStateDescription] = useState('')
  const [stateSelected, setStateSelected] = useState('Managing my account')
  const [stateVerify, setStateVerify] = useState(null)
  const [stateError, setStateError] = useState(null)
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const [loading, setLoading] = useState(null);
  const [themeTextColor, setTheme] = useState(null);
  const [themeBackgroundColor, setThemeBackgroundColor] = useState(null);


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const isDark = undefined;
  // Detecting the default theme
  const systemPrefersDark = useMediaQuery(
    {
      query: "(prefers-color-scheme: dark)",
    },
    undefined
  );


  const value = useMemo(
    () => (isDark === undefined ? !!systemPrefersDark : isDark),
    [isDark, systemPrefersDark]
  );

  useEffect(() => {
    if (value) {
      setTheme('#fff')
      setThemeBackgroundColor('#111')
    } else {
      setTheme('#111')
      setThemeBackgroundColor('#fff')
    }
  }, [value]);

  const onFormSubmit = event => {
    event.preventDefault();

    //this.props.onSubmit(this.state.term);
  };


  const onSubmit = () => {

    // const recaptchaValue = recaptchaRef.current.reset();

    if ((((stateEmail !== '') && (stateName !== '')) && (stateDescription !== ''))) {

      setLoading(true);
      const name = stateName
      const email = stateEmail
      const phoneNumber = statePhone
      const topic = stateSelected
      const description = stateDescription

      axios.post('https://us-central1-uyana-base.cloudfunctions.net/contactFormAlert', { name, email, phoneNumber, topic, description })//the reason we are not sending to a database is because a datebase writing would need premission
        .then(() => {
          setRefreshReCaptcha(r => !r);

          setStateName('')
          setStateEmail('')
          setStatePhone('')
          setStateDescription('')
          setStateSelected('Managing my account')
          setStateVerify(null)
          setStateError(null)
        }).then(() => {

          alert('Thank you, Your message has been submitted successfully!')
          setLoading(false);

        }).catch((error) => {
          alert('Something went wrong')
          setLoading(false);
          console.log(error)
          console.log(error.response.data)
        })
      //  recaptchaRef.current.reset();

    } else {
      setStateError(true)
      setLoading(false);
    }
  }

  // const onChange = (value) => {

  //   setStateVerify(value)
  //   //  console.log("Captcha value:", value);
  // }


  //console.log(this.props)
  return (
    <div >

      <div>
        <HomeDrawer />
      </div>

      <div className='contact-con'>

        <form onSubmit={onFormSubmit} className="ui form">

          <div className='contact-container' style={{ backgroundColor: themeBackgroundColor, }}>


            <Link style={{ textDecoration: 'none' }} className="App-link" to="/">
              <div className="logo" ><h1 style={{ color: themeTextColor, }}>Caribe Match</h1></div>
            </Link>

            <h2 className='blog-pargraph-title' style={{ color: themeTextColor }}>Contact us</h2>

            <p className='contact-us-topic' style={{ color: themeTextColor }}>Your Name*</p>

            <div>
              <input
                type="text"
                value={stateName}
                onChange={(e) => setStateName(e.target.value)}
                placeholder="Your name.."
                className='contact-contain'
              />
            </div>

            {stateError && stateName === '' ? <p style={{ marginTop: 5, color: 'red' }}>Your name is require</p> : null}

            <p className='contact-us-topic' style={{ color: themeTextColor }}>Email Address*</p>

            <div>
              <input
                type="text"
                value={stateEmail}
                onChange={(e) => setStateEmail(e.target.value)}
                placeholder="Your email.."
                className='contact-contain'
              />
            </div>

            {stateError && stateEmail === '' ? <p style={{ marginTop: 5, color: 'red' }}>Your email address is require</p> : null}

            <p className='contact-us-topic' style={{ color: themeTextColor }}>Subject*</p>

            <div >
              <select className='contact-contain'>
                <option value="Managing my account" onClick={() => setStateSelected('Managing my account')} >Managing my account</option>
                <option value="Technical" onClick={() => setStateSelected('Technical')} >Technical</option>
                <option value="Trouble with account login" onClick={() => setStateSelected('Trouble with account login')} >Trouble with account login</option>
                <option value="Comments or Ideas" onClick={() => setStateSelected('Comments or Ideas')} >Comments or Ideas</option>
                <option value="Suggestion" onClick={() => setStateSelected('Suggestion')} >Suggestion</option>
                <option value="Have a safety or privacy concern" onClick={() => setStateSelected('Have a safety or privacy concern')}>Have a safety or privacy concern</option>
                <option value="Question about something else" onClick={() => setStateSelected('Question about something else')}>Question about something else</option>
              </select>
            </div>

            <p className='contact-us-topic' style={{ color: themeTextColor }}>Phone Number (optional)</p>

            <div>
              <input
                type="text"
                value={statePhone}
                onChange={(e) => setStatePhone(e.target.value)}
                placeholder="Your phone.."
                className='contact-contain'
              />
            </div>

            <p className='contact-us-topic' style={{ color: themeTextColor }}>Description*</p>

            <div>
              <textarea
                rows="4"
                cols="50"
                value={stateDescription}
                onChange={(e) => setStateDescription(e.target.value)}
                placeholder="Write something.."
                className='contact-contain'
              ></textarea>
            </div>

            {stateError && stateDescription === '' ? <p style={{ marginTop: 5, color: 'red' }}>A Description is require</p> : null}

            {/*  <input
                  type="file"
                  //  value={this.state.term}
                  onChange={(e) => this.setState({ description: e.target.value })}
                  style={{ color: 'green',marginTop:10,marginBottom:10 }}
                />
                
            <div style={{ marginTop: 5 }}>
              <ReCAPTCHA
                sitekey="6Lc_wugUAAAAAACDkTUWBVCA6FvEMC7Xjtpb0M4j"
                onChange={(value) => onChange(value)}
                ref={recaptchaRef}
              />
            </div> */}
            <div>
              {loading ? <Spinner /> : null}
            </div>

            <div style={{ marginTop: 5 }}>
              <GoogleReCaptchaProvider reCaptchaKey="6Le7h_cnAAAAAEYye3DjzvgwNu8Y6zdJ0TRXzFb0">{/* 6LeLv84lAAAAAIXEO_KiUBglBDrUmK3UbZAFMFXC */}
                <GoogleReCaptcha
                  onVerify={useCallback((token) => { setStateVerify(token); })}
                  refreshReCaptcha={refreshReCaptcha}
                />
              </GoogleReCaptchaProvider>
            </div>

            {stateVerify !== null && !loading ? (
              <div onClick={() => onSubmit()}>
                <div class="submit-btn">Send Message</div>
              </div>
            ) : (
              <div>
                <div class="submit-btn-null">Send Message</div>
              </div>
            )}



          </div>
        </form>
      </div>

    </div>
  );
}

export default Contact;
