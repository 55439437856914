import React, { useEffect, useState, useMemo } from 'react';
import '../../src/Styles.css';
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import couple from '../assets/screenshot_large_5.jpg';
import up from '../assets/up.png';
import HomeDrawer from '../Components/HomeDrawer';


const Blog_8 = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [themeTextColor, setTheme] = useState(null);

    const isDark = undefined;
    // Detecting the default theme
    const systemPrefersDark = useMediaQuery(
        {
            query: "(prefers-color-scheme: dark)",
        },
        undefined
    );


    const value = useMemo(
        () => (isDark === undefined ? !!systemPrefersDark : isDark),
        [isDark, systemPrefersDark]
    );

    useEffect(() => {
        if (value) {
            setTheme('#fff')
        } else {
            setTheme('#111')
        }
    }, [value]);

    return (

        <div >
            <div>
                <HomeDrawer />
            </div>
            <div onClick={() => window.scrollTo(0, 0)} style={{ position: 'fixed', bottom: 10, right: 10, cursor: 'pointer' }}>
                <img src={up} alt="Trulli" className="up-screen" ></img>
            </div>

            <div className='blog-div-container'>
                <div className='blog-content'>


                <Link style={{ textDecoration: 'none' }} className="App-link" to="/">
                        <div className="logo" ><h1 style={{ color: themeTextColor }}>Caribe Match</h1></div>
                    </Link>

                    <h2 className='blog-title-header' style={{ color: themeTextColor }}>Writing The Best Online Dating Profile.</h2>

                    <div style={{ marginTop: 12 }} >
                        <img src={couple} alt="Trulli" className='blog-image'></img>
                    </div>

                    <h3 className='blog-pargraph-autor' style={{ marginTop: 5, color: themeTextColor}}>By Lauren</h3>

                    <p className='blog-pargraph' style={{ color: themeTextColor }}>
                        Portraying yourself accurately in the online dating world means more than just being honest about age, height, and career.
                        It means giving others a clear look into your personality and everyday life, not just the life you aspire
                        to have. Afterall, authentic profiles attract authentic people.

                        <h3 className='blog-pargraph-title1' style={{ marginTop: 15, color: themeTextColor }}>Keep your photos fresh.</h3>

                        You’re probably already aware of how to choose solid profile photos
                        (no filters, good lighting, no hats or sunglasses, and so on). There’s more
                        to consider though. When and where your photos were taken, for example.
                        We recommend keeping them within the last year to ensure they represent
                        the current you. There’s nothing wrong with a snap of you at a black-tie
                        event, but make sure you also have photos of what you look like every day.
                        Lastly, opt for an image that shows off an interest. Whether it’s travel,
                        hiking or hanging out with your four-legged friend, these make the best
                        conversation starters.

                        <h3 className='blog-pargraph-title1' style={{ color: themeTextColor }}>Only select your actual interests.</h3>

                        It might be tempting to select every interest under the sun – we get it,
                        you’re well-rounded – but this can actually do more harm than good. For one,
                        you’re probably not coming across very genuine (no one has time to travel the world while
                        volunteering every weekend, skydiving on a regular basis, and playing on every intramural
                        team). And two, the couple of interests you are serious about will likely get overlooked.

                        <div style={{ marginTop: 10 }} />

                        “Our rule of thumb is if you haven’t done the activity in the last few months,
                        don’t include it in your profile. The same can be said for seasonal interests.
                        Once winter comes to an end, consider updating your profile with your favorite
                        spring and summer pastimes.”

                        <h3 className='blog-pargraph-title1' style={{ color: themeTextColor }}>No need to write a novel.</h3>

                        While we never recommend one-sentence bios or one-word Topic responses, there’s no
                        need to go overboard. Including too many details and specifics in your profile can
                        overwhelm members. Stick to important points like what your everyday life looks
                        like, your personality, and the kind of person you’re looking for. Everything else
                        can be discovered later.

                        <h3 className='blog-pargraph-title1' style={{ color: themeTextColor }}>Be honest about what you want.</h3>

                        If you’re looking for a serious relationship, that should be communicated in your
                        profile. Oftentimes, members will want something long-term but will think that’s
                        not what others want to hear. If you’re not “just looking for someone to hit up
                        happy hours with,” then your bio shouldn’t say so. It’s that simple.

                        <h3 className='blog-pargraph-title1' style={{ color: themeTextColor }}>Get a friend’s opinion.</h3>

                        Your perception of yourself can be slightly skewed, so it helps to get
                        profile feedback from someone who knows you well. Friends are also great at
                        helping you describe your best qualities if you don’t know what to write about.
                    </p>
                </div>
            </div>
        </div>

    );
}


export default Blog_8;
