import React, { useEffect, useState, useMemo } from 'react'
import { useMediaQuery } from "react-responsive";
import '../../../src/Styles.css';
import HomeDrawer from '../HomeDrawer';
import AndriodBage from '../../assets/google-play-badge.png'
import IosBage from '../../assets/iosBage.png'
import { getFirestore, doc, updateDoc, increment } from 'firebase/firestore';
import { initializeApp } from "firebase/app";


const Track1 = () => {

    const minMax = useMediaQuery({ query: '(max-width: 900px)' })

    const [themeTextColor, setTheme] = useState(null);
    const [alreadyCheck, setAlreadyCheck] = useState(true);

    const isDark = undefined;

    const systemPrefersDark = useMediaQuery(
        {
            query: "(prefers-color-scheme: dark)",
        },
        undefined
    );

    const value = useMemo(
        () => (isDark === undefined ? !!systemPrefersDark : isDark),
        [isDark, systemPrefersDark]
    );

    const firebaseConfig = {
        apiKey: "AIzaSyA-8hVRgLp4Rexa-YwWZ96g0p6pFlNp9ck",
        authDomain: "caribe-match.firebaseapp.com",
        projectId: "caribe-match",
        storageBucket: "caribe-match.appspot.com",
        messagingSenderId: "115602412489",
        appId: "1:115602412489:web:adf00e8d7d32a458edce18",
        measurementId: "G-DRSMR1K4E3"
    };

    const app = initializeApp(firebaseConfig);

    const db = getFirestore(app);
    const storyRefAndroid = doc(db, 'Track', 'Android');
    const storyRefiOS = doc(db, 'Track', 'iOS');
    const storyRefMac = doc(db, 'Track', 'Mac');
    const storyRefWindows = doc(db, 'Track', 'Windows');

    useEffect(() => {
        if (value) {
            setTheme('#fff')
        } else {
            setTheme('#111')
        }
    }, [value]);

    useEffect(() => {
        getMobileOperatingSystem()
    }, []);

    async function getMobileOperatingSystem() {
        // Get the user agent string from the browser
        const userAgent = navigator.userAgent;

        // Check if the user agent string contains keywords for different operating systems
        if (userAgent.match(/Windows/i)) {
            try {

                await updateDoc(storyRefWindows, { clicks: increment(1) })
                window.open('https://play.google.com/store/apps/details?id=com.uyana.scripts.app.gy', "_self", "noreferrer")

                console.log('Read count incremented successfully!');
                return null;

            } catch (error) {
                console.error('Error incrementing read count: ', error);
                return null;
            }

        } else if (userAgent.match(/Macintosh|Mac OS/i)) {
            try {

                await updateDoc(storyRefMac, { clicks: increment(1) })

                window.open('https://apps.apple.com/us/app/caribe-match-caribbean-dating/id1665001650', "_self", "noreferrer")

                console.log('Read count incremented successfully!');
                return null;

            } catch (error) {
                console.error('Error incrementing read count: ', error);
                return null;
            }

        } else if (userAgent.match(/Android/i)) {
            try {

                await updateDoc(storyRefAndroid, { clicks: increment(1) })

                window.open('https://play.google.com/store/apps/details?id=com.uyana.scripts.app.gy', "_self", "noreferrer")

                console.log('Read count incremented successfully!');
                return null;

            } catch (error) {
                console.error('Error incrementing read count: ', error);
                return null;
            }

        } else if (userAgent.match(/iOS/i)) {
            try {

                await updateDoc(storyRefiOS, { clicks: increment(1) })
                window.open('https://apps.apple.com/us/app/caribe-match-caribbean-dating/id1665001650', "_self", "noreferrer")

                console.log('Read count incremented successfully!');
                return null;

            } catch (error) {
                console.error('Error incrementing read count: ', error);
                return null;
            }
        } else {
            return null
        }
    }

    return (
        <div >
            <div>
                <HomeDrawer />
            </div>

            <p className="home-logo" style={{ textAlign: 'center', fontSize: 30, marginTop: '20%' }}>Caribe Match</p>

            <div >
                <div >
                    {minMax ? <h3 style={{ textAlign: 'center', fontSize: 28, color: themeTextColor, marginTop: '20%' }}>Choose a Store</h3> : <h3 style={{ textAlign: 'center', fontSize: 30, color: themeTextColor, marginTop: '2%' }}>Choose a Store</h3>}
                </div>

                <div onClick={getMobileOperatingSystem} className='not-found-center'>

                    {minMax ? <img src={AndriodBage} alt="Trulli" width="145" height="65"></img> : <img src={AndriodBage} alt="Trulli" width="155" height="75"></img>}

                    {minMax ? <img src={IosBage} alt="Trulli" width="130" height="41"></img> : <img src={IosBage} alt="Trulli" width="150" height="50"></img>}

                </div>
            </div>

        </div >
    )
}

export default Track1