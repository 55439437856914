import React, { useEffect,useState,useMemo } from 'react';
import '../../src/Styles.css';
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import up from '../assets/up.png'
import HomeDrawer from '../Components/HomeDrawer';


const TermsConditions = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [themeTextColor, setTheme] = useState(null);

  const isDark = undefined;
  // Detecting the default theme
  const systemPrefersDark = useMediaQuery(
    {
      query: "(prefers-color-scheme: dark)",
    },
    undefined
  );


  const value = useMemo(
    () => (isDark === undefined ? !!systemPrefersDark : isDark),
    [isDark, systemPrefersDark]
  );

  useEffect(() => {
    if (value) {
      setTheme('#fff')
    } else {
      setTheme('#111')
    }
  }, [value]);

  return (

    <div className='blog-div-container'>

<div>
          <HomeDrawer />
        </div> 

    <div className='blog-content'>

    <div onClick={() =>  window.scrollTo(0, 0) } style={{position:'fixed',bottom:10,  right:10, cursor: 'pointer'}}>    
     <img src={up} alt="Trulli"  className="up-screen" ></img>
      </div>

      <Link style={{ textDecoration: 'none' }} className="App-link" to="/">
      <div className="logo" ><h1 style={{ color: themeTextColor, }}>Caribe Match</h1></div>
      </Link>
      
      <h2 className='blog-pargraph-title' style={{ color: themeTextColor}}>Terms & Conditions</h2>

        <div>

          <h2 className='blog-pargraph-date' style={{ color: themeTextColor}}>Last revised on March 16, 2024</h2>

          <h2 className='blog-pargraph-sub-title' style={{ color: themeTextColor}}>1. Acceptance of Terms of Use Agreement.</h2>

          <p className='blog-pargraph' style={{ color: themeTextColor}}>Welcome to Caribe Match By creating a Caribe Match account or by using any Yana Plus LLC
          services, whether through a mobile device, mobile application or computer (collectively, the “Service”) you
           agree to be bound by these Terms & Conditions, our <Link style={{ textDecoration: 'none',color:'#2049ff'}} className="App-link" to="/privacy-policy"> Privacy Policy</Link> and <Link style={{ textDecoration: 'none',color:'#2049ff'}} className="App-link" to="/safety-tips"> Safety Tips</Link>, each of which is
            incorporated by reference into this Agreement, and any terms disclosed to you if you purchase or have
             purchased additional features, products or services we offer on the Service (collectively, this “Agreement”).
             If you do not accept and agree to be bound by all of the terms of this Agreement, you should not use the
              Service. We may make changes to this Agreement and to the Service from time to time. We may do this for a variety of reasons including to reflect
changes in or requirements of the law, new features, or changes in business practices. The most recent version of this
Agreement will be posted on the Service under Settings and also on Caribe Match, and you should regularly check for the
most recent version. The most recent version is the version that applies. If the changes include material changes
that affect your rights or obligations, we will notify you in advance of the changes by reasonable means, which could
include notification through the Service or via email. If you continue to use the Service after the changes become
effective, then you agree to the revised Agreement. You agree that this Agreement shall supersede any prior
agreements (except as specifically stated herein), and shall govern your entire relationship with Yana Plus LLC, including but not limited to events,
agreements, and conduct preceding your acceptance of this Agreement.
</p>

          <h2 className='blog-pargraph-sub-title' style={{ color: themeTextColor}}>2. Eligibility.</h2>

          <p className='blog-pargraph' style={{ color: themeTextColor}}>
            You must be at least 18 years of age to create an account on Caribe Match and use the Service. By creating an account and using the Service,
             you represent and warrant that you can form a binding contract with Yana Plus LLC,
  you are not a person who is barred from using the Service under the laws of the United States or any other applicable jurisdiction,
  you will comply with this Agreement and all applicable local, national and international laws, rules and regulations, and
  you have never been convicted of or pled no contest to a felony, a sex crime, or any crime involving violence, and that you are not required to register as a sex offender with any sex offender registry.
           </p>

          <h2 className='blog-pargraph-sub-title' style={{ color: themeTextColor}}>3. Your Account.</h2>

          <p className='blog-pargraph' style={{ color: themeTextColor}}>
            In order to use Caribe Match, you may sign in using your cell phone number, Apple authentication, Google authentication or Facebook authentication.  If you do so, you authorize us to access and use
             certain account information, including but not limited to your public profile and information about
               friends you share in common with other Caribe Match users. For more information regarding the information we
               collect from you and how we use it, please consult our <Link style={{ textDecoration: 'none',color:'#2049ff'}} className="App-link" to="/privacy-policy"> Privacy Policy</Link> 
      </p>
      <p className='blog-pargraph' style={{ color: themeTextColor}}>
     You are responsible for maintaining the
                confidentiality of your login credentials you use to sign up for Caribe Match, and you are solely responsible for all
                activities that occur under those credentials. If you think someone has gained access to your account, please
                 immediately contact us.
</p>

          <h2 className='blog-pargraph-sub-title' style={{ color: themeTextColor}}>4. Modifying the Service and Termination.</h2>

          <p className='blog-pargraph' style={{ color: themeTextColor}}>
          Yana Plus LLC is always striving to improve it's Services to bring you additional functionality that you will find
           engaging and useful. This means we may add new product features or enhancements from time to time as well as
            remove some features, and if these actions do not materially affect your rights or obligations, we may not
             provide you with notice before taking them. We may even suspend the Service entirely, in which event we will
              notify you in advance unless extenuating circumstances, such as safety or security concerns, prevent us from
               doing so. You may terminate your account at any time, for any reason, by following the instructions in 
               “Settings” in the Service.
          </p>

          <h2 className='blog-pargraph-sub-title' style={{ color: themeTextColor}}>5. Safety; Your Interactions with Other Users.</h2>

          <p className='blog-pargraph' style={{ color: themeTextColor}}>
          Though Caribe Match strives to encourage a respectful user experience through features like the double opt-in that 
          only allows users to communicate if they have both indicated interest in one another, it is not responsible for
          the conduct of any user on or off of the Service. You agree to use caution in all interactions with other users,
           particularly if you decide to communicate off the Service or meet in person. In addition, you agree to review 
           and follow Caribe Match <Link style={{ textDecoration: 'none',color:'#2049ff'}} className="App-link" to="/blog4"> Safy Tips </Link> 
       prior to using the Service. 
       </p>

       <p className='blog-pargraph' style={{ color: themeTextColor}}>You agree that you will not provide your financial information (for example, your credit card or bank account information),
        or wire or otherwise send money, to other users.
YOU ARE SOLELY RESPONSIBLE FOR YOUR INTERACTIONS WITH OTHER USERS. YOU UNDERSTAND THAT CARIBE MATCH PLUS LLC DOES NOT CONDUCT CRIMINAL BACKGROUND CHECKS ON ITS USERS OR
 OTHERWISE INQUIRE INTO THE BACKGROUND OF ITS USERS. CARIBE MATCH PLUS LLC MAKES NO REPRESENTATIONS OR WARRANTIES AS TO THE CONDUCT OF USERS. YANA PLUS LLC RESERVES THE RIGHT TO
  CONDUCT – AND YOU AGREE THAT YANA PLUS LLC MAY CONDUCT - ANY CRIMINAL BACKGROUND CHECK OR OTHER SCREENINGS (SUCH AS SEX OFFENDER REGISTER SEARCHES) AT ANY TIME USING AVAILABLE PUBLIC RECORDS.
  </p>

            <h2 className='blog-pargraph-sub-title' style={{ color: themeTextColor}}>6. Rights Yana Plus LLC Grants You.</h2>

            <p className='blog-pargraph' style={{ color: themeTextColor}}>
            Yana Plus LLC grants you a personal, worldwide, royalty-free, non-assignable, nonexclusive, revocable,
             and non-sublicensable license to access and use the Service. This license is for the sole purpose of
              letting you use and enjoy the Service’s benefits as intended by Caribe Match and permitted by this Agreement.
               Therefore, you agree not to:

               <p>. use the Service or any content contained in the Service for any commercial purposes without our written consent.</p>
               <p>. copy, modify, transmit, create any derivative works from, make use of, or reproduce in any way any copyrighted material,
                  images, trademarks, trade names, service marks, or other intellectual property, content or proprietary information accessible
                   through the Service without Yana Plus LLC prior written consent.</p>
                  <p>. express or imply that any statements you make are endorsed by Yana Plus LLC.</p>
                  <p>. use any robot, bot, spider, crawler, scraper, site search/retrieval application, proxy or other manual or automatic device, method or process to access,
 retrieve, index, “data mine,” or in any way reproduce or circumvent the navigational structure or presentation of the Service or its contents.</p>
 <p>. use the Service in any way that could interfere with, disrupt or negatively affect the Service or the servers or networks connected to the Service.</p>

 <p>. upload viruses or other malicious code or otherwise compromise the security of the Service.</p>
<p>
. forge headers or otherwise manipulate identifiers in order to disguise the origin of any information transmitted to or through the Service.
</p>
<p>. “frame” or “mirror” any part of the Service without Yana Plus LLC prior written authorization.
</p>
<p>
. use meta tags or code or other devices containing any reference to Yana Plus LLC or the Service (or any trademark, trade name, service mark,
 logo or slogan of Yana Plus LLC) to direct any person to any other website for any purpose.</p>
 <p>
. modify, adapt, sublicense, translate, sell, reverse engineer, decipher, decompile or otherwise disassemble any portion of the Service, or cause others to do so.</p>

<p>. use or develop any third-party applications that interact with the Service or other users’ Content or information without our written consent.</p>
<p>. use, access, or publish the Yana Plus LLC application programming interface without our written consent.</p>
<p>. probe, scan or test the vulnerability of our Service or any system or network.</p>
<p>. encourage or promote any activity that violates this Agreement.</p>
<p> Yana Plus LLC may investigate and take any available legal action in response to illegal and/ or unauthorized uses of the Service, including termination of your account.</p>
<p>
Any software that we provide you may automatically download and install upgrades, updates, or other new features. You may be able to adjust these automatic downloads through your device’s settings.
</p> 
            </p>
          
            <h2 className='blog-pargraph-sub-title' style={{ color: themeTextColor}}>7. Rights you Grant Yana Plus LLC.</h2>

<p className='blog-pargraph' style={{ color: themeTextColor}}>
By creating an account, you grant to Yana Plus LLC a worldwide, transferable, sub-licensable, royalty-free, right and
 license to host, store, use, copy, display, reproduce, adapt, edit, publish, modify and distribute information you
  authorize us to access from Facebook, as well as any information you post, upload, display or otherwise make
   available (collectively, “post”) on the Service or transmit to other users (collectively, “Content”).
   Yana Plus LLC license to your Content shall be non-exclusive, except that Yana Plus LLC license shall be exclusive
     with respect to derivative works created through use of the Service. For example, Yana Plus LLC would have an
      exclusive license to screenshots of the Service that include your Content. In addition, so that Yana Plus LLC
       can prevent the use of your Content outside of the Service, you authorize Yana Plus LLC to act on your behalf with
        respect to infringing uses of your Content taken from the Service by other users or third parties.
         This expressly includes the authority, but not the obligation, to send notices pursuant to
          17 U.S.C. § 512(c)(3) (i.e., DMCA Takedown Notices) on your behalf if your Content is taken and used by
           third parties outside of the Service. Our license to your Content is subject to your rights under
            applicable law (for example laws regarding personal data protection to the extent any Content contains
             personal information as defined by those laws) and is for the limited purpose of operating, developing,
              providing, and improving the Service and researching and developing new ones. You agree that any Content
               you place or that you authorize us to place on the Service may be viewed by other users and may be viewed by any
                person visiting or participating in the Service (such as individuals who may receive shared Content from other Caribe Match users).
                </p>
                <p className='blog-pargraph' style={{ color: themeTextColor}}>
You agree that all information that you submit upon creation of your account, including information submitted from your Facebook account, is accurate and truthful and you have the right to post the Content on the Service and grant the license to Yana Plus LLC above.
</p>
<p className='blog-pargraph' style={{ color: themeTextColor}}>
You understand and agree that we may monitor or review any Content you post as part of a Service. We may delete any Content, in whole or in part, that in our sole judgment violates this Agreement or may harm the reputation of the Service.
</p>
<p className='blog-pargraph' style={{ color: themeTextColor}}>
When communicating with our customer care representatives, you agree to be respectful and kind. If we feel that your behavior towards any of our customer care representatives or other employees is at any time threatening or offensive, we reserve the right to immediately terminate your account.
</p>
<p className='blog-pargraph' style={{ color: themeTextColor}}>
In consideration for Caribe Match allowing you to use the Service, you agree that we, our affiliates, and our third-party partners may place advertising on the Service. By submitting suggestions or feedback to Yana Plus LLC regarding our Service, you agree that Yana Plus LLC may use and share such feedback for any purpose without compensating you.
</p>
<p className='blog-pargraph' style={{ color: themeTextColor}}>
You agree that Yana Plus LLC may access, preserve and disclose your account information and Content if required to do so by
 law or in a good faith belief that such access, storage or disclosure satisfies a legitimate interest, including to: (i) comply with legal process; (ii) enforce this Agreement; (iii) respond to claims that any Content violates the rights of third parties; (iv) respond to your requests for customer service; or (v) protect the rights, property or personal safety of the Company or any other person.
</p>

<h2 className='blog-pargraph-sub-title' style={{ color: themeTextColor}}>8. Community Rules.</h2>

<p className='blog-pargraph' style={{ color: themeTextColor}}>
By using the Service, you agree that you will not:
<p>
. use the Service for any purpose that is illegal or prohibited by this Agreement.
</p>

<p>. use the Service for any harmful or nefarious purpose.</p>
<p>. use the Service in order to damage Yana Plus LLC.</p>

<p>
. spam, solicit money from or defraud any users.
</p>
<p>
. impersonate any person or entity or post any images of another person without his or her permission.
</p>
<p>
. bully, “stalk,” intimidate, assault, harass, mistreat or defame any person.
</p>
<p>
. post any Content that violates or infringes anyone’s rights, including rights of publicity, privacy, copyright, trademark or other intellectual property or contract right.
</p>
<p>
. post any Content that is hate speech, threatening, sexually explicit or pornographic; incites violence; or contains nudity or graphic or gratuitous violence.
</p>
<p>
. post any Content that promotes racism, bigotry, hatred or physical harm of any kind against any group or individual.
</p>
<p>
. solicit passwords for any purpose, or personal identifying information for commercial or unlawful purposes from other users or disseminate another person’s personal information without his or her permission.
</p>
<p>
. use another user’s account, share an account with another user, or maintain more than one account.
</p>
<p>
. create another account if we have already terminated your account, unless you have our permission.
</p>
</p>
<p className='blog-pargraph' style={{ color: themeTextColor}}>
Caribe Match reserves the right to investigate and/ or terminate your account without a refund of any purchases if you have violated this Agreement, misused the Service or behaved in a way that Caribe Match regards as inappropriate or unlawful, including actions or communications that occur on or off the Service.
</p>

<h2 className='blog-pargraph-sub-title' style={{ color: themeTextColor}}>9. Other Users’ Content.</h2>

<p className='blog-pargraph' style={{ color: themeTextColor}}>
Although Caribe Match reserves the right to review and remove Content that violates this Agreement, such Content is the sole
 responsibility of the user who posts it, and Caribe Match cannot guarantee that all Content will comply with this Agreement.
  If you see Content on the Service that violates this Agreement, please report it within the Service or via
   our <Link style={{ textDecoration: 'none',color:'#2049ff' }} className="App-link" to="/contact-us"> contact form</Link>.
</p>

<h2 className='blog-pargraph-sub-title' style={{ color: themeTextColor}}>10. Other Virtual Items</h2>

<p className='blog-pargraph' style={{ color: themeTextColor}}>
From time to time, you may be able to purchase, a limited, personal, non-transferable, non-sublicensable, revocable
 license to use “virtual items,” including but not limited to (collectively, “Virtual Items”). Any Virtual Item balance
  shown in your account does not constitute a real-world balance or reflect any stored value, but instead constitutes a
   measurement of the extent of your license. Virtual Items do not incur fees for non-use, however, the license granted
    to you in Virtual Items will terminate in accordance with the terms of this Agreement, when Yana Plus LLC ceases providing
     the Service or your account is otherwise closed or terminated. Yana Plus LLC, in its sole discretion, reserves the right 
     to charge fees for the right to access or use Virtual Items and/ or may distribute Virtual Items with or without
      charge. Yana Plus LLC may manage, regulate, control, modify or eliminate Virtual Items at any time. Yana Plus LLC shall have no
       liability to you or any third party in the event that Yana Plus LLC exercises any such rights. Virtual Items may only be
        redeemed through the Service. ALL PURCHASES AND REDEMPTIONS OF VIRTUAL ITEMS MADE THROUGH THE SERVICE ARE FINAL
         AND NON-REFUNDABLE. The provision of Virtual Items for use in the Service is a service that commences immediately
          upon the acceptance of your purchase of such Virtual Items. YOU ACKNOWLEDGE THAT YANA PLUS LLC IS NOT REQUIRED TO PROVIDE
           A REFUND FOR ANY REASON, AND THAT YOU WILL NOT RECEIVE MONEY OR OTHER COMPENSATION FOR UNUSED VIRTUAL ITEMS WHEN
            AN ACCOUNT IS CLOSED, WHETHER SUCH CLOSURE WAS VOLUNTARY OR INVOLUNTARY.
</p>

<h2 className='blog-pargraph-sub-title' style={{ color: themeTextColor}}>11. Copyright policy</h2>

<p className='blog-pargraph' style={{ color: themeTextColor}}>
You may not post, distribute, or reproduce in any way any copyrighted material, trademarks, or other proprietary information without obtaining the prior written consent of the owner of such proprietary rights. Without limiting the foregoing, if you believe that your work has been copied and posted on the Service in a way that constitutes copyright infringement, please provide our Copyright Agent with the following information:
an electronic or physical signature of the person authorized to act on behalf of the owner of the copyright interest;
a description of the copyrighted work that you claim has been infringed;
a description of where the material that you claim is infringing is located on the App (and such description must be reasonably sufficient to enable the Company to find the alleged infringing material);
your address, telephone number and email address;
a written statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law; and
a statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner's behalf.
</p>

<h2 className='blog-pargraph-sub-title' style={{ color: themeTextColor}}>12. Disclaimers.
</h2>

<p className='blog-pargraph' style={{ color: themeTextColor}}>
YANA PLUS LLC PROVIDES THE SERVICE ON AN “AS IS” AND “AS AVAILABLE” BASIS AND TO THE EXTENT PERMITTED BY APPLICABLE LAW, GRANTS NO WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE WITH RESPECT TO THE SERVICE (INCLUDING ALL CONTENT CONTAINED THEREIN), INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF SATISFACTORY QUALITY, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT. YANA PLUS LLC DOES NOT REPRESENT OR WARRANT THAT (A) THE SERVICE WILL BE UNINTERRUPTED, SECURE OR ERROR FREE, (B) ANY DEFECTS OR ERRORS IN THE SERVICE WILL BE CORRECTED, OR (C) THAT ANY CONTENT OR INFORMATION YOU OBTAIN ON OR THROUGH THE SERVICE WILL BE ACCURATE.

YANA PLUS LLC TAKES NO RESPONSIBILITY FOR ANY CONTENT THAT YOU OR ANOTHER USER OR THIRD PARTY POSTS, SENDS OR RECEIVES THROUGH THE SERVICE. ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICE IS ACCESSED AT YOUR OWN DISCRETION AND RISK.

YANA PLUS LLC DISCLAIMS AND TAKES NO RESPONSIBILITY FOR ANY CONDUCT OF YOU OR ANY OTHER USER, ON OR OFF THE SERVICE.
</p>

<h2 className='blog-pargraph-sub-title' style={{ color: themeTextColor}}>13. Third Party Services.</h2>

<p className='blog-pargraph' style={{ color: themeTextColor}}>
The Service may contain advertisements and promotions offered by third parties and links to other web sites or resources.
 Yana Plus LLC is not responsible for the availability (or lack of availability) of such external websites or resources. If you choose to interact with the third parties made available through our Service, such party’s terms will govern their relationship with you.
 Yana Plus LLC is not responsible or liable for such third parties’ terms or actions.
</p>

<h2 className='blog-pargraph-sub-title' style={{ color: themeTextColor}}>14. Limitation of Liability.</h2>

<p className='blog-pargraph' style={{ color: themeTextColor}}>
TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL YANA PLUS LLC, ITS AFFILIATES, EMPLOYEES, LICENSORS OR SERVICE PROVIDERS BE LIABLE FOR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, PUNITIVE, OR ENHANCED DAMAGES, INCLUDING, WITHOUT LIMITATION, LOSS OF PROFITS, WHETHER INCURRED DIRECTLY OR INDIRECTLY, OR ANY LOSS OF DATA, USE, GOODWILL, OR OTHER INTANGIBLE LOSSES, RESULTING FROM: (I) YOUR ACCESS TO OR USE OF OR INABILITY TO ACCESS OR USE THE SERVICE; (II) THE CONDUCT OR CONTENT OF OTHER USERS OR THIRD PARTIES ON, THROUGH OR FOLLOWING USE OF THE SERVICE; OR (III) UNAUTHORIZED ACCESS, USE OR ALTERATION OF YOUR CONTENT, EVEN IF Yana HAS BEEN ADVISED AT ANY TIME OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING THE FOREGOING, IN NO EVENT SHALL Yana’S AGGREGATE LIABILITY TO YOU FOR ANY AND ALL CLAIMS ARISING OUT OF OR RELATING TO THE SERVICE OR THIS AGREEMENT EXCEED THE AMOUNT PAID, IF ANY, BY YOU TO Yana DURING THE TWENTY-FOUR (24) MONTH PERIOD IMMEDIATELY PRECEDING THE DATE THAT YOU FIRST FILE A LAWSUIT, ARBITRATION OR ANY OTHER LEGAL PROCEEDING AGAINST Yana, WHETHER IN LAW OR IN EQUITY, IN ANY TRIBUNAL. THE DAMAGES LIMITATION SET FORTH IN THE IMMEDIATELY PRECEDING SENTENCE APPLIES (i) REGARDLESS OF THE GROUND UPON WHICH LIABILITY IS BASED (WHETHER DEFAULT, CONTRACT, TORT, STATUTE, OR OTHERWISE), (ii) IRRESPECTIVE OF THE TYPE OF BREACH OF OBLIGATIONS, AND (iii) WITH RESPECT TO ALL EVENTS, THE SERVICE, AND THIS AGREEMENT.

THE LIMITATION OF LIABILITY PROVISIONS SET FORTH IN THIS SECTION 14 SHALL APPLY EVEN IF YOUR REMEDIES UNDER THIS AGREEMENT FAIL WITH RESPECT TO THEIR ESSENTIAL PURPOSE.

SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES, SO SOME OR ALL OF THE EXCLUSIONS AND LIMITATIONS IN THIS SECTION MAY NOT APPLY TO YOU.
</p>

<h2 className='blog-pargraph-sub-title' style={{ color: themeTextColor}}>15. Arbitration and Governing Law.</h2>

<p className='blog-pargraph' style={{ color: themeTextColor}}>
Arbitration Agreement. The exclusive means of resolving any dispute or claim arising out of or relating to this Agreement (including any alleged breach thereof), the Service, or the App shall be BINDING ARBITRATION. The one exception to the exclusivity of arbitration is that you have the right to bring an individual claim against the Company in a small-claims court of competent jurisdiction. But whether you choose arbitration or small-claims court, you may not under any circumstances commence or maintain against the Company any class action, class arbitration, or other representative action or proceeding.
Notice of Rights. By using the Caribe Match or the Service in any manner, you agree to the above arbitration agreement. In doing so, YOU GIVE UP YOUR RIGHT TO GO TO COURT to assert or defend any claims between you and the Company (except for matters that may be taken to small-claims court). YOU ALSO GIVE UP YOUR RIGHT TO PARTICIPATE IN A CLASS ACTION OR OTHER CLASS PROCEEDING. Your rights will be determined by a NEUTRAL ARBITRATOR, NOT A JUDGE OR JURY, and the arbitrator shall determine all issues regarding the arbitrability of the dispute. You are entitled to a fair hearing before the arbitrator. The arbitrator can grant any relief that a court can, but you should note that arbitration proceedings are usually simpler and more streamlined than trials and other judicial proceedings. Decisions by the arbitrator are enforceable in court and may be overturned by a court only for very limited reasons. For details on the arbitration process.
Court Proceedings. Any proceeding to enforce this arbitration agreement, including any proceeding to confirm, modify, or vacate an arbitration award, may be commenced in any court of competent jurisdiction. In the event that this arbitration agreement is for any reason held to be unenforceable, any litigation against the Company (except for small-claims court actions) may be commenced only in courts located in GYana. You hereby irrevocably consent to the jurisdiction of those courts for such purposes.
Governing Law. This Agreement, and any dispute between you and the Company, shall be governed by the laws of United States without regard to principles of conflicts of law, provided that this arbitration agreement shall be governed by the GYana Arbitration Act.

</p>

<h2 className='blog-pargraph-sub-title' style={{ color: themeTextColor}}>16. Indemnity by You.</h2>

<p className='blog-pargraph' style={{ color: themeTextColor}}>
You agree, to the extent permitted under applicable law, to indemnify, defend and hold harmless Yana Plus LLC,
 our affiliates, and their and our respective officers, directors, agents, and employees from and against any and all
  complaints, demands, claims, damages, losses, costs, liabilities and expenses, including attorney’s fees, due to,
   arising out of, or relating in any way to your access to or use of the Service, your Content, or your breach of this
    Agreement.
</p>

<h2 className='blog-pargraph-sub-title' style={{ color: themeTextColor}}>17. Notice.</h2>

<p className='blog-pargraph' style={{ color: themeTextColor}}>
The Company may provide you with notices, including those regarding changes to this Agreement, using any reasonable 
means now known or hereafter developed, including by email, regular mail, SMS, MMS, text message or postings on the App.
 Such notices may not be received if you violate this Agreement by accessing the Service in an unauthorized manner.
  You agree that you are deemed to have received any and all notices that would have been delivered had you accessed the
  Service in an authorized manner.
</p>

<h2 className='blog-pargraph-sub-title' style={{ color: themeTextColor}}>18. Entire Agreement; Other.</h2>

<p className='blog-pargraph' style={{ color: themeTextColor}}>
This Agreement, along with the <Link style={{ textDecoration: 'none',color:'#2049ff'}} className="App-link" to="/privacy-policy"> Privacy Policy</Link>, the <Link style={{ textDecoration: 'none',color:'#2049ff'}} className="App-link" to="/safety-tips"> Safety Tips</Link> and any terms disclosed to you if you purchase or have
 purchased additional features, products or services we offer on the Service, contains the entire agreement between you
  and Caribe Match regarding your relationship with Caribe Match and the use of the Service, with the following exception: anyone
   who opted out of the retroactive application of Section 15 is still subject to and bound by any prior agreements to
    arbitrate with Caribe Match as well as this agreement to arbitrate on a going forward basis. If any provision of this
     Agreement is held invalid, the remainder of this Agreement shall continue in full force and effect. The failure of
      Caribe Match to exercise or enforce any right or provision of this Agreement shall not constitute a waiver of such right
       or provision. You agree that your Caribe Match account is non-transferable and all of your rights to your account and
        its Content terminate upon your death. No agency, partnership, joint venture, fiduciary or other special
         relationship or employment is created
 as a result of this Agreement and you may not make any representations on behalf of or bind Yana Plus LLC in any manner.
</p>

        </div>
      </div>
    </div>
  );
}

export default TermsConditions;
