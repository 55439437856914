import React, { useEffect,useState,useMemo } from 'react';
import '../../src/Styles.css';
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import up from '../assets/up.png'
import HomeDrawer from '../Components/HomeDrawer';


const SafetyTips = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [themeTextColor, setTheme] = useState(null);

  const isDark = undefined;
  // Detecting the default theme
  const systemPrefersDark = useMediaQuery(
    {
      query: "(prefers-color-scheme: dark)",
    },
    undefined
  );


  const value = useMemo(
    () => (isDark === undefined ? !!systemPrefersDark : isDark),
    [isDark, systemPrefersDark]
  );

  useEffect(() => {
    if (value) {
      setTheme('#fff')
    } else {
      setTheme('#111')
    }
  }, [value]);

  return (
    <div className='blog-div-container'>

<div>
          <HomeDrawer />
        </div> 
    <div className='blog-content'>

    <div onClick={() =>  window.scrollTo(0, 0) } style={{position:'fixed',bottom:10,  right:10, cursor: 'pointer'}}>    
     <img src={up} alt="Trulli"  className="up-screen" ></img>
      </div>
      
      <Link style={{ textDecoration: 'none' }} className="App-link" to="/">
      <div className="logo" ><h1 style={{ color: themeTextColor, }}>Caribe Match</h1></div>
      </Link>

      <h2 className='blog-pargraph-title' style={{ color: themeTextColor}}>Safety Tips</h2>

        <div>

          <p className='blog-pargraph' style={{ color: themeTextColor}}>At Caribe Match, user safety is a priority. We understand that meeting someone for the
           first time whether online, through an acquaintance or on an outing is intriguing and exciting. However, your safety
            is very important and because you using Caribe Match we want you to have a great experience, there are certain
            safety steps that you should follow while dating – both online and offline.</p>

          <p className='blog-pargraph' style={{ color: themeTextColor}}>We ask you to read the tips and information below, and strongly urge you to follow
          these guidelines in the interest of your personal safety and well-being. However, you are always the best judge of
            your own safety, and these guidelines are not intended to be a substitute for your own judgment.</p>

          <h2 className='safty-pargraph-title' style={{ color: themeTextColor}}>Online Behavior</h2>

          <h3 className='safty-pargraph-title1' style={{ color: themeTextColor}}>Finance: Protect Your Finances & Never Send Money or Financial Information</h3>

          <ul style={{ listStyleType: 'square' }}>
            <li className='blog-pargraph' style={{ color: themeTextColor}}>
              Never respond to any request to send money, especially overseas or by wire transfer, and report it to us
               immediately – even if the person claims to be in an emergency. Wiring money is like sending cash: the sender has no
                protections against loss and it’s nearly impossible to reverse the transaction or trace the money. For more information,
                 click on the video below for advice to avoid online romance scams, available here:
</li>
          </ul>

          <div className='video-algin'>
          <iframe width="420" height="345" src="https://www.youtube.com/embed/jlxWxH0mgU8"/>
          </div>

          <h3 className='safty-pargraph-title1' style={{ color: themeTextColor}}>Protect Your Personal Information</h3>
          <ul style={{ listStyleType: 'square' }}>
            <li className='blog-pargraph' style={{ color: themeTextColor}}>Never give personal information such as your social security number, credit card
             number or bank information, or your work or home address to people you don’t know
 or haven’t met in person.</li>
            <li className='blog-pargraph' style={{ color: themeTextColor}}>Note: Caribe Match will never send you an email asking for your username and password
 information. Any such communications should be reported immediately.</li>
          </ul>

          <h3 className='safty-pargraph-title1' style={{ color: themeTextColor}}>Be Web Wise</h3>
          <ul style={{ listStyleType: 'square' }}>
            <li className='blog-pargraph' style={{ color: themeTextColor}}>
              Block and report suspicious users. You can block and report concerns about any suspicious user anonymously from any
               profile page, email or messaging window. Keep conversations on the platform.
 Bad actors will try to move the conversation to text, personal email or phone conversations.</li>
          </ul>

          <h3 className='safty-pargraph-title1' style={{ color: themeTextColor}}>Report All Suspicious Behavior</h3>
          <ul style={{ listStyleType: 'square' }}>
            <li className='blog-pargraph' style={{ color: themeTextColor}}>
              Additionally, please report anyone who violates our terms & conditions <Link style={{ textDecoration: 'none',color:'#faff00' }} className="App-link" to="/contact-us"> here</Link>. Examples of terms & conditions violations include:</li>
            <ul style={{ listStyleType: 'square' }}>
              <li className='blog-pargraph' style={{ color: themeTextColor}}>Asking you for money or donations.</li>
              <li className='blog-pargraph' style={{ color: themeTextColor}}>Requesting photographs.</li>
              <li className='blog-pargraph' style={{ color: themeTextColor}}>Minors using the platform.</li>
              <li className='blog-pargraph' style={{ color: themeTextColor}}>Users sending harassing or offensive messages.</li>
              <li className='blog-pargraph' style={{ color: themeTextColor}}>Users behaving inappropriately during or after meeting in person.</li>
              <li className='blog-pargraph' style={{ color: themeTextColor}}>Fraudulent registration or profiles.</li>
              <li className='blog-pargraph' style={{ color: themeTextColor}}>Spam or solicitation, such as invitations to call numbers or attempts to sell products or service.</li>
            </ul>
          </ul>

          <h2 className='safty-pargraph-title' style={{ color: themeTextColor}}>Offline Behavior</h2>

          <p className='blog-pargraph' style={{ color: themeTextColor}}>First in-person meetings are exciting, but always take precautions and follow these guidelines to help you stay safe.</p>

          <h3 className='safty-pargraph-title1' style={{ color: themeTextColor}}>Get to Know the Other Person</h3>
          <ul style={{ listStyleType: 'square' }}>
            <li className='blog-pargraph' style={{ color: themeTextColor}}>Keep your communications on the platform and really get to know users online/using the
             app before meeting them in person. Bad actors often push people to communicate off the platform immediately. It’s up to you to research and do your due diligence.</li>
             </ul>

          <h3 className='safty-pargraph-title1' style={{ color: themeTextColor}}>Always Meet and Stay in Public</h3>
          <ul style={{ listStyleType: 'square' }}>
            <li className='blog-pargraph' style={{ color: themeTextColor}}>Meet for the first few times in a populated, public place – never in a private or remote
 location and never at your date’s home or apartment. If your date pressures you, end the date and leave at once.</li>
          </ul>

          <h3 className='safty-pargraph-title1' style={{ color: themeTextColor}}>Tell Your Friends and Family Members of Your Plans</h3>
          <ul style={{ listStyleType: 'square' }}>
            <li className='blog-pargraph' style={{ color: themeTextColor}}>Inform a friend or family member of your plans and when and where you’re going. Make sure you have
 your cell phone charged and with you at all times.</li>
          </ul>

          <h3 className='safty-pargraph-title1' style={{ color: themeTextColor}}>Transport Yourself to and from the Meeting</h3>
          <ul style={{ listStyleType: 'square' }}>
            <li className='blog-pargraph' style={{ color: themeTextColor}}>You need to be independent and in control of your own transportation, especially in case things don’t work out.</li>
          </ul>

          <h3 className='safty-pargraph-title1' style={{ color: themeTextColor}}>Stay Sober</h3>
          <ul style={{ listStyleType: 'square' }}>
            <li className='blog-pargraph' style={{ color: themeTextColor}}>Consumption of alcohol and/or other drugs can impair your judgment and potentially put you in danger. It’s important to keep a clear mind and avoid anything that might place you at risk.
 Be aware that bad actors might try to take advantage of you by altering your beverage(s) with synthetic substances.</li>
          </ul>

          <h3 className='safty-pargraph-title1' style={{ color: themeTextColor}}>For Further Help, Support or Advice</h3>
          <p className='blog-pargraph' style={{ color: themeTextColor}}>In the case that something has happened, immediately contact the nearest police station or call 911. Emergency situations include a recent threat of violence or
           sexual violence, recent act of violence or sexual violence or if your health or someone else’s is in danger.</p>
{/* 
          <p className='blog-pargraph' style={{ color: themeTextColor}}>If something has happened and you’re in need of help, support
           or advice pertaining to physical or sexual assault, please call the hotlines below.</p>

          <h3 className='safty-pargraph-title1' style={{ color: themeTextColor}}>Rape, Abuse and Incest National Hotline</h3>
          <p className='blog-pargraph' style={{ color: themeTextColor}}>Tel: 1-800-656-HOPE | www.rainn.org</p>

          <h3 className='safty-pargraph-title1' style={{ color: themeTextColor}}>GYana Responsible Parenthood Association</h3>
          <p className='blog-pargraph' style={{ color: themeTextColor}}>Tel: (592) 225-3286| https://www.ippfwhr.org/en/country/gYana</p>

          <h3 className='safty-pargraph-title1' style={{ color: themeTextColor}}>Help And Shelter - Domestic Violence</h3>
          <p className='blog-pargraph' style={{ color: themeTextColor}}>Tel: (592) - 225-4731, 227-8353 |http://www.hands.org.gy/</p>

          <h3 className='safty-pargraph-title1' style={{ color: themeTextColor}}>Police</h3>
          <p className='blog-pargraph' style={{ color: themeTextColor}}>Tel: 911</p> */}

        </div>
      </div>
    </div>
  );
}


export default SafetyTips;