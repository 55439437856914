import React, { useEffect, useState, useMemo } from 'react';
import '../../src/Styles.css';
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import couple from '../assets/screenshot_large_6.jpg';
import up from '../assets/up.png';
import HomeDrawer from '../Components/HomeDrawer';


const Blog_5 = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [themeTextColor, setTheme] = useState(null);
  
    const isDark = undefined;
    // Detecting the default theme
    const systemPrefersDark = useMediaQuery(
      {
        query: "(prefers-color-scheme: dark)",
      },
      undefined
    );
  
  
    const value = useMemo(
      () => (isDark === undefined ? !!systemPrefersDark : isDark),
      [isDark, systemPrefersDark]
    );
  
    useEffect(() => {
      if (value) {
        setTheme('#fff')
      } else {
        setTheme('#111')
      }
    }, [value]);

    return (

        <div >
            <div>
                <HomeDrawer />
            </div>
            <div onClick={() => window.scrollTo(0, 0)} style={{ position: 'fixed', bottom: 10, right: 10, cursor: 'pointer' }}>
                <img src={up} alt="Trulli" className="up-screen" ></img>
            </div>

            <div className='blog-div-container'>
                <div className='blog-content'>


                <Link style={{ textDecoration: 'none' }} className="App-link" to="/">
                <div className="logo" ><h1 style={{ color: themeTextColor }}>Caribe Match</h1></div>
            </Link>
            
                    <h2 className='blog-title-header' style={{ color: themeTextColor }}>How Do You Get Your Matches To Meet Offline?
                    </h2>

                    <div style={{ marginTop: 12 }} >
                        <img src={couple} alt="Trulli" className='blog-image'></img>
                    </div>

                    <h3 className='blog-pargraph-autor' style={{ marginTop: 5, color: themeTextColor  }}>By Christan Marashio</h3>

                    <p className='blog-pargraph' style={{ color: themeTextColor }}>
                        This one is simple: ask them. Rather than focus on who asks whom, seize the moment and suggest
                        a video-chat. For the past three months, people have had time to familiarize themselves with
                        Zoom, Google Hangouts, etc. A video chat is low-stakes enough that most people won't feel as
                        pressured to take things to the next level.

                        <div style={{ marginTop: 15, color: themeTextColor }} />

                        When you first begin to message, don't use that time to ask interview-style questions.
                        Use something in their bio or profile as a springboard for light conversation. Do they cook?
                        Travel? Meditate? Use that as a conversation starter.

                        <div style={{ marginTop: 15, color: themeTextColor }} />

                        Understand that, since most people access dating apps and dating sites using their
                        phone, involved conversations will get tedious very quickly. This is where many
                        people lose their match's interest. Those platforms are not made to have ongoing
                        conversations. They're for chatting only.

                        <div style={{ marginTop: 15, color: themeTextColor }} />

                        Give the conversation about 3-4 total messages each and then say, "Hey, I'm really
                        enjoying talking  with you.  If you have some time later, want to chat on zoom
                        for a few minutes?"

                        <div style={{ marginTop: 15, color: themeTextColor }} />

                        Understand that most people might not have the flexibility to hop on a spur of
                        the moment video call. That's too much pressure, even for extroverts or people
                        who are comfortable talking to strangers. That's why you should suggest a later
                        time within the next few hours. I don't suggest waiting longer than a day, as
                        that leaves too much time for them to over-think or, worse, continue swiping or
                        messaging.

                        <div style={{ marginTop: 15, color: themeTextColor }} />

                        If they're interested, they'll say yes. If they're ambivalent or change
                        the subject, take that as a sign they're either a time-waster or not interested
                        in meeting up. Don't internalize their response. Think of it as saving yourself
                        a lot of time and emotional bandwidth.

                    </p>
                </div>
            </div>
        </div>

    );
}

export default Blog_5;
