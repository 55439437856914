import React, { useEffect, useState, useMemo } from 'react';
import '../../src/Styles.css';
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import couple from '../assets/screenshot_large_7.jpg';
import up from '../assets/up.png';
import HomeDrawer from '../Components/HomeDrawer';


const Blog_10 = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [themeTextColor, setTheme] = useState(null);

    const isDark = undefined;
    // Detecting the default theme
    const systemPrefersDark = useMediaQuery(
        {
            query: "(prefers-color-scheme: dark)",
        },
        undefined
    );


    const value = useMemo(
        () => (isDark === undefined ? !!systemPrefersDark : isDark),
        [isDark, systemPrefersDark]
    );

    useEffect(() => {
        if (value) {
            setTheme('#fff')
        } else {
            setTheme('#111')
        }
    }, [value]);

    return (

        <div >
            <div>
                <HomeDrawer />
            </div>
            <div onClick={() => window.scrollTo(0, 0)} style={{ position: 'fixed', bottom: 10, right: 10, cursor: 'pointer' }}>
                <img src={up} alt="Trulli" className="up-screen" ></img>
            </div>

            <div className='blog-div-container'>
                <div className='blog-content'>

                <Link style={{ textDecoration: 'none' }} className="App-link" to="/">
                        <div className="logo" ><h1 style={{ color: themeTextColor }}>Caribe Match</h1></div>
                    </Link>

                    <h2 className='blog-title-header' style={{ color: themeTextColor }}>Let’s get you to the first date faster.</h2>

                    <div style={{ marginTop: 12 }} >
                        <img src={couple} alt="Trulli" className='blog-image'></img>
                    </div>

                    <h3 className='blog-pargraph-autor' style={{ marginTop: 5, color: themeTextColor }}>By Sylvi</h3>

                    <p className='blog-pargraph' style={{ color: themeTextColor }}>
                        You can message back and forth all day long, but when it comes to an actual date, you
                        have to put yourself out there. While the rom-coms make asking someone out look like
                        second nature, we know there’s usually some anxiety involved. No shame.

                        <h3 className='blog-pargraph-title1' style={{ marginTop: 15, color: themeTextColor }}>How do I ask for a date without coming off as pushy or too eager?</h3>

                        Let your match know that you’re enjoying getting to know them and would like to meet
                        up sometime soon. Ask them what their schedule is like. This confirms your interest
                        in spending time with them and is a low-pressure invitation. It’s also a great way
                        to feel out if the interest is mutual without making it awkward.

                        <h3 className='blog-pargraph-title1' style={{ color: themeTextColor }}>I was turned down. What should I do?</h3>

                        We’ve all been there. If it’s clear that there will be no further contact, let them know that you
                        appreciate their time and wish them well. If it’s a temporary situation – for example, your match
                        is going out of town or has previous plans – ask again in a few days or sooner if your match brings
                        it up. Life gets busy, so “no” doesn’t always mean never.

                        <h3 className='blog-pargraph-title1' style={{ color: themeTextColor }}>My match accepted the date. Now how do I know who pays?</h3>

                        It’s 2023, so there’s no more hard-and-fast rules on who pays. However, if you
                        initiated the date, be prepared to pay for both of you. If you didn’t initiate the
                        date but feel strongly about paying or splitting the bill, feel free to let them
                        know how you like to handle things beforehand. For example, you can work into the
                        conversation, “I’m old school and pay for all of my dates. I hope that’s okay with
                        you.” Or, “I know you asked me out, but I picked the restaurant, so can we split
                        the bill this time?” If it doesn’t come up naturally and you feel strongly about
                        paying, you can always excuse yourself to the bathroom and quietly take care of
                        the check before returning.

                        <h3 className='blog-pargraph-title1' style={{ color: themeTextColor }}>My last date didn’t look anything like their photos. How do I avoid this happening again?</h3>

                        Start by looking at your match’s photos and asking them to tell you about them. For example, if they have a lot of travel pictures,
                        you can ask when they took those trips. Due to the pandemic, some photos may be over a year old. You can
                        also look out for signs of discrepancy, such as obvious or heavy editing, super close headshots where
                        you can’t see their entire face, and no full-body photos.

                        <div style={{ marginTop: 10 }} />

                        I also always recommend scheduling a Vibe Check prior to setting up a date. Video chats not only give
                        you a chance to talk and see each other live, but can ease some of the anxiety that comes with meeting
                        in person for the first time.­­
                    </p>
                </div>
            </div>
        </div>

    );
}


export default Blog_10;
