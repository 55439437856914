import React, { useEffect, useState, useMemo } from 'react';
import '../../src/Styles.css';
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import couple from '../assets/screenshot_small_10.jpg';
import up from '../assets/up.png';
import HomeDrawer from '../Components/HomeDrawer';


const Blog_8 = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [themeTextColor, setTheme] = useState(null);

    const isDark = undefined;
    // Detecting the default theme
    const systemPrefersDark = useMediaQuery(
        {
            query: "(prefers-color-scheme: dark)",
        },
        undefined
    );


    const value = useMemo(
        () => (isDark === undefined ? !!systemPrefersDark : isDark),
        [isDark, systemPrefersDark]
    );

    useEffect(() => {
        if (value) {
            setTheme('#fff')
        } else {
            setTheme('#111')
        }
    }, [value]);

    return (

        <div >
            <div>
                <HomeDrawer />
            </div>
            <div onClick={() => window.scrollTo(0, 0)} style={{ position: 'fixed', bottom: 10, right: 10, cursor: 'pointer' }}>
                <img src={up} alt="Trulli" className="up-screen" ></img>
            </div>

            <div className='blog-div-container'>
                <div className='blog-content'>


                <Link style={{ textDecoration: 'none' }} className="App-link" to="/">
                        <div className="logo" ><h1 style={{ color: themeTextColor }}>Caribe Match</h1></div>
                    </Link>

                    <h2 className='blog-title-header' style={{ color: themeTextColor }}>Signs Your Date Is Toxic</h2>

                    <div style={{ marginTop: 12 }} >
                        <img src={couple} alt="Trulli" className='blog-image'></img>
                    </div>

                    <h3 className='blog-pargraph-autor' style={{ marginTop: 5,  color: themeTextColor  }}>By Christan Marashio</h3>

                    <p className='blog-pargraph' style={{ color: themeTextColor }}>
                        We'd been sitting at our corner two-top table for less than thirty minutes when it
                        started. I could barely finish a sentence without him cutting me off or turning the
                        conversation back to him. At first I thought he was nervous, so I allowed him to do it,
                        hoping he'd eventually course-correct.

                        <div style={{ marginTop: 10 }} />
                        He didn't.
                        <div style={{ marginTop: 10 }} />
                        As the youngest child in my family, being talked over was and still is a huge pet peeve
                        of mine. Eventually, I'd had enough.
                        <div style={{ marginTop: 10 }} />
                        "Do you realize you keep interrupting me?" I asked.
                        <div style={{ marginTop: 10 }} />
                        "Oh," he said, smiling. "Do I?"
                        <div style={{ marginTop: 10 }} />
                        And then he did it again. That was my cue to leave. There are certain red flags I never
                        ignore. Ignoring expressed boundaries is at the top of the list.
                        <div style={{ marginTop: 10 }} />
                        Here are a few more:

                        <h3 className='blog-pargraph-title1' style={{ marginTop: 15,  color: themeTextColor  }}>They disrespect your boundaries.</h3>

                        Setting boundaries is crucial in all forms of relationships. You need to be able to
                        draw lines in the sand to make clear what is and isn't acceptable behavior. If people
                        ignore those boundaries, they're telling you your needs don't matter.

                        <h3 className='blog-pargraph-title1' style={{ color: themeTextColor }}>They appear and disappear without warning.</h3>

                        Everything will be moving along great and then they'll go radio silent out of nowhere. They do this to
                        keep you off-balance and make you worry you did something wrong.

                        <h3 className='blog-pargraph-title1' style={{ color: themeTextColor }}>Their communication style abruptly changes.</h3>

                        One minute they're writing verbose texts and the next they're giving you one word
                        answers. They'll regularly respond right away then take several hours to reply. Like
                        the sign above, they do this to keep you off-kilter and make you wonder if you somehow
                        upset them.

                        <h3 className='blog-pargraph-title1' style={{ color: themeTextColor }}>They turn the tables.</h3>

                        You set a date, but when the night you planned to meet comes, you don't hear from them,
                        send a text to confirm the day of and get no response, or they stand you up. When you
                        address the issue, they put the blame on you, saying they assumed you were blowing
                        them off because they didn't hear from you within a certain time-frame.

                        <h3 className='blog-pargraph-title1' style={{ color: themeTextColor }}>They deflect.</h3>

                        When you try to discuss something that's bothering you, they change the subject or
                        dredge up something from the past as a distraction. They'll do anything to avoid
                        accountability. They can turn what should be a simple conversation into a full-blown
                        argument in minutes.

                        <h3 className='blog-pargraph-title1' style={{ color: themeTextColor }}>They get upset if you don't take their advice.</h3>

                        Toxic people need to special at all times. If you don't implement whatever tips they
                        give you for handling a certain scenario, they feel wounded. To them, it means you
                        don't think enough of them to do what they tell you to do. If you overlook this,
                        eventually you will have no agency in the relationship.

                        <h3 className='blog-pargraph-title1' style={{ color: themeTextColor }}>They're never wrong.</h3>

                        You could present proof their answer to a question is incorrect and they'll find a way to make it right.
                        "That's not what you said!" "That was a trick question!"
                        This sort of reaction indicates they have a fragile ego that bruises easily. To engage them means to
                        be constantly walking on eggshells.

                        <h3 className='blog-pargraph-title1' style={{ color: themeTextColor }}>They have no concept of time, yours or anyone else's.</h3>

                        They're constantly late, usually arriving well after you or everyone else in attendance.
                        They never tell you they're running behind, often causing you to worry. They have difficulty
                        setting a specific time or date. By behaving this way, they're ensuring they have the entirety
                        of your focus.

                        <h3 className='blog-pargraph-title1' style={{ color: themeTextColor }}>They downplay your feelings or opinions.</h3>

                        This is why someone's expressed affection for snark or sarcasm in a dating profile is a red flag.
                        With this statement, they're telling you they enjoy making pointed comments at other people's
                        expense. They make cutting remarks and then, when they see they've offended you, quickly add,
                        "Oh, come on! It was a joke!"  When someone tells you they're hurt by your behavior. the only
                        appropriate response is an apology. Which brings me to...

                        <h3 className='blog-pargraph-title1' style={{ color: themeTextColor }}>They suck at apologizing.</h3>

                        "I'm sorry I did that but..." is not an apology. A true apology is one where the person
                        a) acknowledges what they did wrong and b) takes full ownership of their behavior
                        without blaming someone else.

                        <h3 className='blog-pargraph-title1' style={{ color: themeTextColor }}>They complain but never want to do anything to fix the situation.</h3>

                        Rather than make a move to improve something that makes them unhappy - like a job or
                        friendship - they use you as their perpetual sounding board. They prefer to identify as
                        a victim because - you guessed it - they get to be the center of attention.

                        <h3 className='blog-pargraph-title1' style={{ color: themeTextColor }}>They're always at odds with someone.</h3>

                        After oxygen, conflict is this person's life force. This fulfills two needs - their
                        need to be a victim (and thereby the center of attention) and their addiction to
                        drama.

                        <h3 className='blog-pargraph-title1' style={{ color: themeTextColor }}>When confronted, their behavior will temporarily improve then revert back to the toxic behavior.</h3>

                        This is trauma bonding in its purest form. It demonstrates they can better their behavior at any
                        time if it suits their need.
                        The biggest issue with dating someone who is toxic is that the entire relationship
                        becomes about them. Your feelings, needs, boundaries do not exist. Worse, they'll
                        eventually start to gaslight you, telling you you're not remembering things
                        correctly or are too sensitive.

                        <h3 className='blog-pargraph-title1' style={{ color: themeTextColor }}>It's important to label this behavior what it is: abuse..</h3>

                        Everybody goes through stages where they need extra attention, but if it's constant, that's a sign of dysfunction.

                    </p>
                </div>
            </div>
        </div>

    );
}


export default Blog_8;
