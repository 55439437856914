import React, { useEffect, useState, useMemo } from 'react';
import '../../src/Styles.css';
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import couple from '../assets/screenshot_large_1.jpg';
import up from '../assets/up.png';
import HomeDrawer from '../Components/HomeDrawer';


const Blog_7 = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [themeTextColor, setTheme] = useState(null);

    const isDark = undefined;
    // Detecting the default theme
    const systemPrefersDark = useMediaQuery(
        {
            query: "(prefers-color-scheme: dark)",
        },
        undefined
    );


    const value = useMemo(
        () => (isDark === undefined ? !!systemPrefersDark : isDark),
        [isDark, systemPrefersDark]
    );

    useEffect(() => {
        if (value) {
            setTheme('#fff')
        } else {
            setTheme('#111')
        }
    }, [value]);
    return (

        <div >
            <div>
                <HomeDrawer />
            </div>
            <div onClick={() => window.scrollTo(0, 0)} style={{ position: 'fixed', bottom: 10, right: 10, cursor: 'pointer' }}>
                <img src={up} alt="Trulli" className="up-screen" ></img>
            </div>

            <div className='blog-div-container'>
                <div className='blog-content'>


                    <Link style={{ textDecoration: 'none' }} className="App-link" to="/">
                        <div className="logo" ><h1 style={{ color: themeTextColor }}>Caribe Match</h1></div>
                    </Link>

                    <h2 className='blog-title-header' style={{ color: themeTextColor }}>Can You Meet The Right Person At The Wrong Time?</h2>

                    <div style={{ marginTop: 12 }} >
                        <img src={couple} alt="Trulli" className='blog-image'></img>
                    </div>

                    <h3 className='blog-pargraph-autor' style={{ marginTop: 5, color: themeTextColor }}>By Christan Marashio</h3>

                    <p className='blog-pargraph' style={{ color: themeTextColor }}>
                        I'm fascinated by this question because  of the role-reversal. Usually, I'm getting
                        letters from people who are  in the position of the men in this story. They're involved
                        with someone,  they think things are going well, then they get the bad news: the guy
                        doesn't want a relationship/doesn't see a long-term future/isn't ready  etc.
                        I think, more often than not, when a man tells a woman  they've been dating casually for a period of time
                        that they aren't  looking for a relationship, what they actually mean is, "I'm not  interested in a
                        relationship with you." That results in women  believing that all the men on dating apps are just looking
                        for sex or  something casual. When, really, a lot of those men are committing.  Just...not to them.

                        <div style={{ marginTop: 15, color: themeTextColor }} />

                        One thing this letter demonstrates is how people can commit while fully aware they might
                        change their mind in the  near future. Either, like this woman, they're dating multiple
                        people and  feel compelled to make a choice or it's a case of right person/wrong  time.
                        Yes, they do exist. Many times, people feel obligated to choose  someone and lock it
                        down out of fear. Fear of being alone. Fear of  missing out on a great person. The
                        intention behind the choice is  benevolent more often than people realize.

                        <div style={{ marginTop: 15, color: themeTextColor }} />

                        This is a good  explanation for why you might date someone casually for a few months
                        and  they might end things but still try to stay in your life. They're not  quite
                        ready to let go. They're not fully confident they're making the  right decision.

                        <div style={{ marginTop: 15, color: themeTextColor }} />

                        How do you know if they're the right person?  That's just it: you don't. It's only by
                        taking the risk that you find  out. Sometimes it works out. Sometimes it doesn't.
                        It doesn't work until  it works. You have to be willing to put in the time and take
                        the  necessary risks to find a real lasting relationship.

                        <div style={{ marginTop: 15, color: themeTextColor }} />

                        As for your  question, it sounds to me like you're not ready to commit to either of
                        these men. The fact that you want to keep Guy #2 as a friend "just in  case" should
                        be an indication that you're not sure Guy #1 is the someone  you want to be with
                        long-term. Is it wrong to want to stay friends with  Guy #1 in order to keep him in
                        your life should things not work with  Guy #1? I mean, yeah, but who ever said love
                        (or life, for that matter)  was fair? This is dating.

                        <div style={{ marginTop: 15, color: themeTextColor }} />

                        This scenario plays out all the time, most people just aren't aware of it.

                        <div style={{ marginTop: 15, color: themeTextColor }} />

                        My  honest opinion is that you shouldn't agree to be exclusive with either  of these men.
                        You don't owe either of them anything more that common  decency and honesty, but you need to
                        first be honest with yourself. What  - and more specifically who - do you want to be with. Think
                        about that in both abstract and specific capacities. That way, when  you're truly ready to commit,
                        you'll have a better chance of choosing  the right person.

                        <div style={{ marginTop: 15, color: themeTextColor }} />

                        Good luck! Please update me on how things work out.

                    </p>
                </div>
            </div>
        </div>

    );
}

export default Blog_7;
