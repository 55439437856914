import React, { useEffect, useState, useMemo } from 'react';
import '../../src/Styles.css';
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import couple from '../assets/screenshot_large_9.jpg';
import up from '../assets/up.png'
import HomeDrawer from '../Components/HomeDrawer';



const Blog_4 = () => {

    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])
  
    const [themeTextColor, setTheme] = useState(null);
  
    const isDark = undefined;
    // Detecting the default theme
    const systemPrefersDark = useMediaQuery(
      {
        query: "(prefers-color-scheme: dark)",
      },
      undefined
    );
  
  
    const value = useMemo(
      () => (isDark === undefined ? !!systemPrefersDark : isDark),
      [isDark, systemPrefersDark]
    );
  
    useEffect(() => {
      if (value) {
        setTheme('#fff')
      } else {
        setTheme('#111')
      }
    }, [value]);
  
        return (

            <div >
                <div>
                     <HomeDrawer />
                </div>
                <div onClick={() => window.scrollTo(0, 0)} style={{ position: 'fixed', bottom: 10, right: 10, cursor: 'pointer' }}>
                    <img src={up} alt="Trulli" className="up-screen" ></img>
                </div>

                <div className='blog-div-container'>
                    <div className='blog-content'>

                    <Link style={{ textDecoration: 'none' }} className="App-link" to="/">
                    <div className="logo" ><h1 style={{ color: themeTextColor }}>Caribe Match</h1></div>
                </Link>

                        <h2 className='blog-title-header' style={{ color: themeTextColor }}>How To Tell If You're On A Date Or If They’re Just Being Friendly.</h2>


                        <div style={{ marginTop: 12 }} >
                            <img src={couple} alt="Trulli" className='blog-image'></img>
                        </div>

                        <h3 className='blog-pargraph-autor' style={{ marginTop: 5, color: themeTextColor  }}>By CAROLINE COLVIN</h3>

                        <p className='blog-pargraph' style={{ color: themeTextColor }}> Imagine this: You've finally secured plans to meet up at your favorite restaurant
                            with the person you've been crushing on forever or maybe that friend-of-a-friend you've always thought was fine AF.
                            Giddy with anticipation, you drop this piping hot Earl Grey into your group chat and tear apart your closet to find
                            the pants that make your butt look superb. But just as you're discerning what to do with your hair, how you'll slay
                            your face, and if you should slip some condoms or dental dams into your bag,
                            a haunting question strikes you: Is this actually a date, or are you guys simply hanging out? Whether the sobering
                            question came from your own mind or that one tell-it-like-it-is friend in your crew, you need answers. Situations
                            like these are tough because, as dating coach Clara Artschwager tells Elite Daily, "The physical landscape of a
                            romantic or platonic gathering can look the same: an intimate drink, a movie, a cozy dinner, a long stroll. It's
                            the energy and intention behind it [that makes a difference], and often we go into a dating not knowing either of
                            those things."Here are a few ways Artschwager and Chioma Joy Petway, a therapist at Achieving Joy,
                            recommend approaching this gray area.</p>

                        <h3 className='blog-pargraph-title1' style={{ color: themeTextColor }}>1. Look At Their Non-Verbal Behavior</h3>
                        <p className='blog-pargraph' style={{ color: themeTextColor }}>If you're in that awkward stretch between making plans with your crush and actually linking
                            up, you're probably pondering that lingering glance they gave you or re-reading their texts like a cryptographer. As Artschwager
                            puts it, you're asking yourself, "Does their like or follow or kissy-face emoji mean they 'like' like me, or do they send that
                            to everyone?" As you're trying to solve the riddle of whether you're going on a romantic date, remember that people get nervous around
                            their crushes. "So flushed faces, nervous eye contact, or bumbling over words could indicate that this feeling started before this moment,"
                            Petway says. "And the bold asker is moving out of their comfort zone."

                            When you two finally meet, you can figure out how they feel based on things like if they touch you on the shoulder or
                            if they go in for a hug.</p>

                        <h3 className='blog-pargraph-title1' style={{ color: themeTextColor }}>2. Ask for Clarification</h3>
                        <p className='blog-pargraph' style={{ color: themeTextColor }}>
                            Still, Petway says non-verbal clues on their own aren't enough for you to puzzle out if you're
                            on a date. "There is no way to determine what behaviors mean what for the person you are with
                            without talking first," she explains. So, she says, you've got to be bold and ask.
                            "Your self-worth is not wrapped in their answer," Petway says. "The quicker we release our hopes from people who don’t
                            hope to be with us, the quicker we find the person who wants to enjoy the process of getting to know our uniqueness
                            as much as we are excited to get to know theirs."
                            Unfortunately, the vagueness of these situations (and the awkwardness of asking) can be exacerbated if you're queer.
                            If your crush is also a woman or non-binary person, it might be hard to tell whether them asking to grab smoothies
                            was a friendly gesture or them making a move™. There might be an extra layer of murkiness if you're not out at work
                            or school, or you're unsure of your crush's sexuality.
                            Petway, who has dated women and men, says that being honest about your feelings upfront will work out
                            best — especially if you've just met or the stakes are low. "If they don’t have the same interest, they will
                            generally tell you," Petway says. "If you are wrong and they are not gay (or not interested), you now know and you
                            can move forward."
                        </p>

                        <h3 className='blog-pargraph-title1' style={{ color: themeTextColor }}>3. Lead With Your Own Romantic Desires</h3>
                        <p className='blog-pargraph' style={{ color: themeTextColor }}>
                            As you gather the members of your crush committee to pore over screenshots and plot your next
                            move, try considering your own feelings first — instead acting solely on how your crush feels
                            or how you think they feel. "We're so quick to seek clarification from the other person
                            before we get clear on how we feel ourselves," Artschwager says. "Both what we're seeking
                            romantically or platonically at this point in time, and how we feel about this person."
                            Petway echoes this, saying, "Rather trying to decide what the other person is doing and feeling, my encouragement is
                            to check in with yourself [during your hang-out session or afterward]. Do you like this person? What is happening in
                            your body? Are you bored? Is the conversation not your speed?"
                            If you really are into your crush and want to know where they stand, Artschwager recommends
                            saying something along the lines of, "I've really enjoyed spending time together and I feel I should be honest with
                            you. I am in a place in my life where I'm seeking and insert what you're looking for, be it a committed romantic
                            relationship, a FWB arrangement, or a casual date situation. I'd like to explore that with you, but I'm not sure
                            exactly where you're at."
                            There's no perfect time to declare your feelings and intentions. You might be compelled to text your crush on your
                            Uber ride home, or you might want maybe go on another date to feel things out — something both Artschwager and Petway
                            recommend. It just depends on what you're comfortable with and where you are in your dating life.
                            Regardless of whether you can decode your crush's tone or emoji selection, put on those legendary pants. Clip in that
                            pearl barrette or break out that eccentric top you've been dying to wear all year anyway. You'll be well-equipped
                            show up to the "date," and figure out what you want as well as get what you deserve — whether that's date number
                            two or a little more clarity.
                        </p>
                    </div>

                </div>
            </div >

        );
    }



export default Blog_4;
