import React, { useEffect, useState, useMemo } from 'react';
import '../../src/Styles.css';
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import couple from '../assets/screenshot_large_8.jpg';
import up from '../assets/up.png'
import HomeDrawer from '../Components/HomeDrawer';


const Blog_6 = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [themeTextColor, setTheme] = useState(null);
  
    const isDark = undefined;
    // Detecting the default theme
    const systemPrefersDark = useMediaQuery(
      {
        query: "(prefers-color-scheme: dark)",
      },
      undefined
    );
  
  
    const value = useMemo(
      () => (isDark === undefined ? !!systemPrefersDark : isDark),
      [isDark, systemPrefersDark]
    );
  
    useEffect(() => {
      if (value) {
        setTheme('#fff')
      } else {
        setTheme('#111')
      }
    }, [value]);

    return (

        <div >
            <div>
                <HomeDrawer />
            </div>
            <div onClick={() => window.scrollTo(0, 0)} style={{ position: 'fixed', bottom: 10, right: 10, cursor: 'pointer' }}>
                <img src={up} alt="Trulli" className="up-screen" ></img>
            </div>

            <div className='blog-div-container'>
                <div className='blog-content'>

                <Link style={{ textDecoration: 'none' }} className="App-link" to="/">
                <div className="logo" ><h1 style={{ color: themeTextColor }}>Caribe Match</h1></div>
            </Link>

                    <h2 className='blog-title-header' style={{ color: themeTextColor }}>How to end it politely</h2>


                    <div style={{ marginTop: 12 }} >
                        <img src={couple} alt="Trulli" className='blog-image'></img>
                    </div>

                    <h3 className='blog-pargraph-autor' style={{ marginTop: 5, color: themeTextColor  }}>By Ilissa</h3>

                    <p className='blog-pargraph' style={{ color: themeTextColor }}>
                        Not every match you talk to will end in happily ever after, and that’s okay. But ending a
                        relationship – or what could have become a relationship – can be tricky. It may seem easier
                        to just “ghost” or “slow-fade,” but it’s important to remember there’s a real person on the
                        other side of the screen.

                        <div style={{ marginTop: 10, color: themeTextColor  }} />

                        A previous Learning Coach in the luxury hospitality industry, Ilissa has trained numerous
                        new hires to reach their full potential. She takes that same direct and practical approach
                        to show singles how to increase their success one message at a time.

                        <h3 className='blog-pargraph-title1' style={{ marginTop: 15, color: themeTextColor  }}>How to end it when: You haven’t met in person yet but have been messaging for a while.</h3>

                        Sometimes you don’t have to meet face to face to know the chemistry is not there.
                        If you’ve sent a few messages back and forth or exchanged phone numbers, I recommend
                        sending a short message letting them know you’re no longer interested. For example,

                        <div style={{ marginTop: 10, color: themeTextColor  }} />

                        “It’s been great getting to know you. I don’t think we’re a good match for dating,
                        but I wish you all the best in your search going forward.”

                        <div style={{ marginTop: 10, color: themeTextColor  }} />

                        If they respond and ask for an explanation, it’s up to you if you’d like to elaborate.
                        Just know you’ve already done your due diligence by being direct with them.

                        <h3 className='blog-pargraph-title1' style={{ color: themeTextColor }}>You’ve gone on one date.</h3>
                        You don’t have to call or meet in person to break things off. A quick text is more than
                        appropriate.For example,

                        <div style={{ marginTop: 10, color: themeTextColor  }} />

                        “Thanks for meeting up last night. After spending some time together, I don’t think our
                        personalities are the right fit. But I wish you the best and hope you find what you’re
                        looking for.”

                        <div style={{ marginTop: 10, color: themeTextColor  }} />

                        If your date was rude, awkward or not what you were expecting, it’s best to be upfront
                        sooner rather than later. Consider a more direct approach like, “Thank you for meeting
                        with me tonight. I wanted to reach out and let you know I’m not interested in going out
                        again.”

                        <div style={{ marginTop: 10, color: themeTextColor  }} />

                        This message is clear, concise and direct without being cruel or allowing the other
                        person to think there might be a chance to go out again.

                        <h3 className='blog-pargraph-title1' style={{ color: themeTextColor }}> You’ve gone on several dates.</h3>

                        When you’ve been out multiple times and established a deeper connection, you shouldn’t
                        end things over text.

                        <div style={{ marginTop: 10, color: themeTextColor  }} />

                        If you opt for a phone call, be sure to let them know you need to talk in advance and confirm a time
                        prior to calling. This way you don’t end up leaving a voicemail or catching them off guard.

                        <div style={{ marginTop: 10, color: themeTextColor  }} />

                        If you decide to end things in person, be sure you’re in an environment that’s conducive
                        to conversation. Avoid crowded places like loud restaurants or busy coffee shops.

                        <div style={{ marginTop: 10, color: themeTextColor  }} />

                        For either option, the conversation can follow a similar flow. Start off with something positive,
                        then explain what problems you’ve been having and end it with your next steps. Be sure to give them a
                        chance to respond and share their feelings as well. For example,

                        <div style={{ marginTop: 10, color: themeTextColor  }} />

                        “I’ve really enjoyed the time we’ve spent together. You’re always fun to be around.
                        However, I noticed that you’re unresponsive when we’re not together and it’s hard for
                        me to get in touch with you. I really need someone who makes communication a priority.
                        I’m willing to listen if you feel differently or if we can work on finding a balance.
                        But if not, then it’s best if we no longer see each other.”


                    </p>
                </div>
            </div>
        </div>

    );
}


export default Blog_6;
