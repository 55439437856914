import React from 'react';
import '../../src/Styles.css'

const Spinner = () => {
  return (
    <div className="spinner">
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default Spinner;

