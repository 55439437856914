import React, { useEffect, useState, useMemo } from 'react';
import '../../src/Styles.css';
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import couple from '../assets/screenshot_large_2.jpg'
import up from '../assets/up.png';
import HomeDrawer from '../Components/HomeDrawer';

const Blog_3 = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [themeTextColor, setTheme] = useState(null);

  const isDark = undefined;
  // Detecting the default theme
  const systemPrefersDark = useMediaQuery(
    {
      query: "(prefers-color-scheme: dark)",
    },
    undefined
  );


  const value = useMemo(
    () => (isDark === undefined ? !!systemPrefersDark : isDark),
    [isDark, systemPrefersDark]
  );

  useEffect(() => {
    if (value) {
      setTheme('#fff')
    } else {
      setTheme('#111')
    }
  }, [value]);

  return (

    <div >
      <div>
        <HomeDrawer />
      </div>
      <div onClick={() => window.scrollTo(0, 0)} style={{ position: 'fixed', bottom: 10, right: 10, cursor: 'pointer' }}>
        <img src={up} alt="Trulli" className="up-screen" ></img>
      </div>

      <div className='blog-div-container'>
        <div className='blog-content'>


          <Link style={{ textDecoration: 'none' }} className="App-link" to="/">
            <div className="logo" ><h1 style={{ color: themeTextColor }}>Caribe Match</h1></div>
          </Link>

          <h2 className='blog-title-header' style={{ color: themeTextColor }}> 21 Personality Questions To Ask On A First Date That'll Give You A Sense Of Their True Self.</h2>

          <div style={{ marginTop: 12 }} >
            <img src={couple} alt="Trulli" className='blog-image'></img>
          </div>

          <h3 className='blog-pargraph-autor' style={{ marginTop: 5, color: themeTextColor }}>By GRIFFIN WYNNE</h3>

          <p className='blog-pargraph' style={{ color: themeTextColor }}>
            For me, there is nothing worse than, "So, what kind of music do you like?" on a first date.
            Someone who doesn't know me asking me to list off what I'm "into" feels less like genuine
            interest in me and my passions, and more of a "cool club" audition. There are many
            personality questions to ask on a first date that can't be answered with pre-rehearsed
            lists.
            First dates are nerve-racking! What to wear? Where to go? What to order? In addition to all
            of these questions,
            I don't want to also have to worry about which Hypebeast Rappers I should namedrop in order
            to seem cool.
            A question as simple as rephrasing, "What kind of music do you like?" into "Any new music out that you're excited
            about?" allows you to see how animated your date gets when talking about the things that they love. You are
            amazing as you,
            and though it may seem intimidating — a first date is an opportunity to be yourself and let your personality shine.
            Here are 21 questions to ask your date that can give you a glimpse into your potential boo's truest self.
          </p>

          <h3 className='blog-pargraph-title1' style={{ color: themeTextColor }}>1. What was the best part of you day?</h3>
          <p className='blog-pargraph' style={{ color: themeTextColor }}>This gives you a chance to see what little things stick out to
            them during the day, what makes them happy, and how good their memory actually is.</p>

          <h3 className='blog-pargraph-title1' style={{ color: themeTextColor }}>2. What were you for Halloween this year?</h3>
          <p className='blog-pargraph' style={{ color: themeTextColor }}>OK, This can show if your date is playful, recently single
            (was it a couples costume?), if they take themselves too seriously, or how into pop culture
            they are. Also, it provides a chance to show each other Halloween pics on Instagram,
            and casually drop a, "Oh, I'll follow you" if you haven't yet made a move.</p>

          <h3 className='blog-pargraph-title1' style={{ color: themeTextColor }}>3. Do you like to read?</h3>
          <p className='blog-pargraph' style={{ color: themeTextColor }}>Rather than pressing them on what they're reading, asking if they
            like to read can be a way to talk about shared interests, or your own passion.</p>

          <h3 className='blog-pargraph-title1' style={{ color: themeTextColor }}>4.Do you go out a lot?</h3>
          <p className='blog-pargraph' style={{ color: themeTextColor }}>"What's your party mood?" is a good variation too. Are you the DJ?
            Guarding the guac? Dancing on a table? This is a good way to see your social compatibility and assess
            whether or not they will dance with you in public.</p>

          <h3 className='blog-pargraph-title1' style={{ color: themeTextColor }}>5. Go-to Karaoke song?</h3>
          <p className='blog-pargraph' style={{ color: themeTextColor }}>This one is soul-baring. Do they sing in public? Are they comfortable on
            stage? Do they like to be silly? Also, if you don't like to dance in public or sing in front of others,
            this can provide some insight into whether or not your date likes to be in the spotlight, as well as if
            they'll try to pull you into it with them.</p>

          <h3 className='blog-pargraph-title1' style={{ color: themeTextColor }}>6. Mountains or beach?</h3>
          <p className='blog-pargraph' style={{ color: themeTextColor }}>Travel can be a privilege. Not everyone has been to other countries or been
            on big vacations. Rather than "Where is the best place you've traveled?" or "Favorite vacation?" asking
            about favorite geographic landscapes can get a sense of your boo's tastes, without making someone who
            hasn't travelled feel excluded.
            Do they want to live in the woods and be a hermit? Do they want to be on the MTV Spring Break special? Do they like hot weather
            or cold weather? Trees or ocean?</p>

          <h3 className='blog-pargraph-title1' style={{ color: themeTextColor }}>7. Do you talk to your family often?</h3>
          <p className='blog-pargraph' style={{ color: themeTextColor }}>Family is a hot topic. Asking about frequency of talking to family
            can be a laid-back way of asking "What's the vibe here?" while still giving your boo an easy out.
            If they give you a quick "No" and don't seem to want to talk about it more, let it rest. If they go on for an hour about
            their dad's Bitmoji (me), you'll learn all about their relationship to with their fam.</p>

          <h3 className='blog-pargraph-title1' style={{ color: themeTextColor }}>8. Do you find food places online and read reviews, or do you walk
            around until you find something?</h3>
          <p className='blog-pargraph' style={{ color: themeTextColor }}>For me this is an opposites attract. I'm a big time just walk around, so
            dating another just walk around often means that the entire date is just walking around, and ends with
            two hungry and crabby people.</p>

          <h3 className='blog-pargraph-title1' style={{ color: themeTextColor }}>9. What's the worst job you've ever had?</h3>
          <p className='blog-pargraph' style={{ color: themeTextColor }}>Swapping bad job stories can be a fun way to learn about someone's past,
            and all the hard work they've put into getting to where they are now.</p>

          <h3 className='blog-pargraph-title1' style={{ color: themeTextColor }}>10. Are you super techie?</h3>
          <p className='blog-pargraph' style={{ color: themeTextColor }}>This can be a way to talk about the internet, social media, and the role
            of technology in modern society, without asking, "So what do you feel about the internet, social media,
            and the role of technology in modern society?" ten minutes into a first date. Also, a good way to suss
            out if they can potentially fix your computer.</p>

          <h3 className='blog-pargraph-title1' style={{ color: themeTextColor }}>11. What's your favorite season to dress for?</h3>
          <p className='blog-pargraph' style={{ color: themeTextColor }}>Note: This isn't favorite season in general. If they're into layering they may
            be a fall/winter boo, whereas a light fabrics bae might be a summer lover. Asking about dressing for
            seasons can open up the convo about the role style or clothes play in their lives.</p>

          <h3 className='blog-pargraph-title1' style={{ color: themeTextColor }}>12. Are you into routines?</h3>
          <p className='blog-pargraph' style={{ color: themeTextColor }}>It can help to plan for future dates if you know that your date likes to have
            every minute of everyday planned and finds routines comforting, or if they make it up as they go along.</p>

          <h3 className='blog-pargraph-title1' style={{ color: themeTextColor }}>13. Do you think about celebrities a lot?</h3>
          <p className='blog-pargraph' style={{ color: themeTextColor }}>Learning about how you date relates to the media can provide insight on how
            they think about the world or what kind of entertainment they care about.</p>

          <h3 className='blog-pargraph-title1' style={{ color: themeTextColor }}>14. Do you like to make things?</h3>
          <p className='blog-pargraph' style={{ color: themeTextColor }}>Someone may not see themselves as "an artist" or "a carpenter" even if they make
            amazing things. Asking your boo if they like to make things, or what they've been making can be a cool way to
            hear if they're visually creative.</p>

          <h3 className='blog-pargraph-title1' style={{ color: themeTextColor }}>15. How did you meet your best friend?</h3>
          <p className='blog-pargraph' style={{ color: themeTextColor }}>A good precursor to: How long have you been friends? What is their deal? Have you
            told them about me?</p>

          <h3 className='blog-pargraph-title1' style={{ color: themeTextColor }}>16. Are you a food sharer?</h3>
          <p className='blog-pargraph' style={{ color: themeTextColor }}>This can be good to ask before ordering. If they are a tapas and small plates fan,
            sharing food can be an intimate and cute way to get more comfortable around each other. If they are a strict my
            food kind of person, you'll probably want to know that before you reach reaching your fork over, and getting the
            death eyes.</p>

          <h3 className='blog-pargraph-title1' style={{ color: themeTextColor }}>17. Do you clean before you have company over?</h3>
          <p className='blog-pargraph' style={{ color: themeTextColor }}>If you're a Monica from Friends, you may not be able to date a Phoebe.</p>

          <h3 className='blog-pargraph-title1' style={{ color: themeTextColor }}>18. How long have you lived here?</h3>
          <p className='blog-pargraph' style={{ color: themeTextColor }}>This can be a less intimating than the dreaded, "Tell me your story." Relating
            questions to the place you're both currently in can open convo about what brought you both there.</p>

          <h3 className='blog-pargraph-title1' style={{ color: themeTextColor }}>19. What are your "Treat Yo' Self" items?</h3>
          <p className='blog-pargraph' style={{ color: themeTextColor }}>Coffee beans? Face wash? Hearing about the things your boo will splurge on can teach
            you a lot about them, and what matters to them.</p>

          <h3 className='blog-pargraph-title1' style={{ color: themeTextColor }}>20. Are you into sports?</h3>
          <p className='blog-pargraph' style={{ color: themeTextColor }}>Hearing about someone's relationship with sports will often mean hearing about their
            family's relationship to sports, their hometowns relationship to sports, and their own relationship to physical
            activities.</p>

          <h3 className='blog-pargraph-title1' style={{ color: themeTextColor }}>21. What's your tipping vibe?</h3>
          <p className='blog-pargraph' style={{ color: themeTextColor }}>If you're a big tipper, and your date doesn't leave a dime, this may show some
            differences you feel about compensating for labor. If they're a big tipper, it may mean they've worked in food
            service before and try to be a compassionate costumer.
            Asking questions on a first date can be a great way to learn new things about someone you're interested in. Of course, it's natural to be
            intimidated on a date with a new cutie, and it's good to be open-minded and patient when hearing about what makes
            them tick. If they're a Nervous Nelly, they may not be their chill, joking, lovey self until a couple dates in. But,
            no matter the questions your potential boo asks you, you already have the answer — just be yourself.</p>

        </div>
      </div>
    </div>

  );
}


export default Blog_3;
