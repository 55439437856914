import React, { useEffect, useState, useCallback, useMemo } from 'react';
import '../../src/Styles.css';
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { connect } from 'react-redux';
import '../../src/Styles.css'
import { BlogsFetch } from '../actions/BlogAction'
import Background from '../assets/screenshot.jpg'
import couple1 from '../assets/screenshot_small_1.jpg'
import couple2 from '../assets/screenshot_small_2.jpg'
import couple3 from '../assets/screenshot_small_3.jpg'
import couple4 from '../assets/screenshot_small_4.jpg'
import couple5 from '../assets/screenshot_small_5.jpg'
import couple6 from '../assets/screenshot_small_6.jpg'
import couple7 from '../assets/screenshot_small_7.jpg'
import couple8 from '../assets/screenshot_small_8.jpg'
import couple9 from '../assets/screenshot_small_9.jpg'
import couple10 from '../assets/screenshot_small_10.jpg'

import HomeDrawer from '../Components/HomeDrawer';

const Advises = () => {//?contine to style web and mobile

    // componentWillMount() {

    //     //  this.props.BlogsFetch();
    // }
    //style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }} 
    // flex-direction: row;
    // align-items: center;
    // justify-content: center;
    // width:300px ;
    var sectionStyle = {
        width: "100%",
        height: 100,
        //  backgroundSize: 'cover',
        //  backgroundImage: `url(${Background})`,
        backgroundColor: '#22222a'
    };

    const [themeTextColor, setTheme] = useState(null);
    const [themeBackgroundColor, setThemeBackgroundColor] = useState(null);


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const isDark = undefined;
    // Detecting the default theme
    const systemPrefersDark = useMediaQuery(
        {
            query: "(prefers-color-scheme: dark)",
        },
        undefined
    );


    const value = useMemo(
        () => (isDark === undefined ? !!systemPrefersDark : isDark),
        [isDark, systemPrefersDark]
    );

    useEffect(() => {
        if (value) {
            setTheme('#fff')
            setThemeBackgroundColor('#111')
        } else {
            setTheme('#111')
            setThemeBackgroundColor('#fff')
        }
    }, [value]);

    return (

        <div >

            <div>
                <HomeDrawer />
            </div>

            <div className='div-container'>

            <Link style={{ textDecoration: 'none' }} className="App-link" to="/">
                <div className="logo" ><h1 style={{ color: themeTextColor, }}>Caribe Match</h1></div>
            </Link>

            <h2 className='blog-pargraph-title' style={{ color: themeTextColor }}>Dating Tips</h2>

                <Link style={{ textDecoration: 'none' }} className="App-link" to="/10-astonishing-things-that-happen-when-you-meet-your-soulmate">
                    <div className='div-contain' style={{ backgroundColor: themeBackgroundColor, }}>

                        <div>
                            <img src={couple3} alt="img" className='blog-image-cover'></img>
                        </div>

                        <div>
                            <div  >
                                <h3 className='blog-title-style' style={{ color: themeTextColor }}>10 Astonishing Things That Happen When You Meet Your Soulmate</h3>
                            </div>
                            <div className='blog-text-style-block'>
                                <span className='blog-text-style' style={{ color: themeTextColor }} >
                                    They say if you want something in life the whole universe conspires to make it happen.
                                    Similar is the case with love. Love is an unfathomable emotion and people desire it the most...
                                </span>
                            </div>

                            <div className='write-text-style'>
                                <p style={{ fontSize: 14, color: themeTextColor }} >Crystal Aav</p>
                            </div>
                        </div>
                    </div>
                </Link>

                <Link style={{ textDecoration: 'none' }} className="App-link" to="/things-to-talk-about-when-on-a-date">
                    <div className='div-contain' style={{ backgroundColor: themeBackgroundColor, }}>

                        <div>
                            <img src={couple4} alt="img" className='blog-image-cover' ></img>
                        </div>

                        <div>
                            <div >
                                <h3 className='blog-title-style' style={{ color: themeTextColor }} >Things To Talk About When On a Date</h3>
                            </div>
                            <div className='blog-text-style-block' >
                                <span className='blog-text-style' style={{ color: themeTextColor }} >
                                    For many men, talking with their girlfriends is something that somehow seems natural.
                                    In reality however, this is an art form that has to be mastered. No matter how close you are to your
                                    girlfriend...
                                </span>
                            </div>

                            <div className='write-text-style'>
                                <p style={{ fontSize: 14, color: themeTextColor }} >Crystal Aav</p>
                            </div>
                        </div>
                    </div>
                </Link>

                <Link style={{ textDecoration: 'none' }} className="App-link" to="/21-personality-questions-to-ask-on-a-first-date-that-give-you-a-sense-of-their-true-self">
                    <div className='div-contain' style={{ backgroundColor: themeBackgroundColor, }}>

                        <div>
                            <img src={couple2} alt="img" className='blog-image-cover'></img>
                        </div>

                        <div>
                            <div >
                                <h3 className='blog-title-style' style={{ color: themeTextColor }} >21 Personality Questions To Ask On A First Date That'll Give You A Sense Of Their True Self</h3>
                            </div>
                            <div className='blog-text-style-block'>
                                <span className='blog-text-style' style={{ color: themeTextColor }}  >
                                    For me, there is nothing worse than, "So, what kind of music do you like?" on a first date.
                                    Someone who doesn't know me asking me to list off what I'm "into" feels less like genuine...
                                </span>
                            </div>

                            <div className='write-text-style'>
                                <p style={{ fontSize: 14, color: themeTextColor }} >GRIFFIN WYNNE</p>
                            </div>

                        </div>
                    </div>
                </Link>

                <Link style={{ textDecoration: 'none' }} className="App-link" to="/how-to-tell-if-you-are-on-a-date-or-if-there-just-being-friendly">
                    <div className='div-contain' style={{ backgroundColor: themeBackgroundColor, }}>

                        <div>
                            <img src={couple9} alt="img" className='blog-image-cover'></img>
                        </div>
                        <div>
                            <div>
                                <h3 className='blog-title-style' style={{ color: themeTextColor }} >How To Tell If You're On A Date Or If They’re Just Being Friendly</h3>
                            </div>
                            <div className='blog-text-style-block'>
                                <span className='blog-text-style' style={{ color: themeTextColor }}  >
                                    Imagine this: You've finally secured plans to meet up at your favorite restaurant
                                    with the person you've been crushing on forever or maybe that friend-of-a-friend you've always thought was fine AF....
                                </span>
                            </div>
                            <div className='write-text-style'>
                                <p style={{ fontSize: 14, color: themeTextColor }} >CAROLINE COLVIN</p>
                            </div>
                        </div>
                    </div>
                </Link>

                <Link style={{ textDecoration: 'none' }} className="App-link" to="/how-do-you-get-your-matches-to-meet-offline">
                    <div className='div-contain' style={{ backgroundColor: themeBackgroundColor, }}>

                        <div>
                            <img src={couple6} alt="img" className='blog-image-cover'></img>
                        </div>
                        <div>
                            <div >
                                <h3 className='blog-title-style' style={{ color: themeTextColor }} >How Do You Get Your Matches To Meet Offline?</h3>
                            </div>
                            <div className='blog-text-style-block'>
                                <span className='blog-text-style' style={{ color: themeTextColor }}  >
                                    This one is simple: ask them. Rather than focus on who asks whom, seize the moment and suggest
                                    a video-chat. For the past three months, people have had time to familiarize themselves with....
                                </span>
                            </div>
                            <div className='write-text-style'>
                                <p style={{ fontSize: 14, color: themeTextColor }} >Christan Marashio</p>
                            </div>
                        </div>
                    </div>
                </Link>

                <Link style={{ textDecoration: 'none' }} className="App-link" to="/how-to-end-it-politely">
                    <div className='div-contain' style={{ backgroundColor: themeBackgroundColor, }}>

                        <div>
                            <img src={couple8} alt="img" className='blog-image-cover'></img>
                        </div>
                        <div>
                            <div >
                                <h3 className='blog-title-style' style={{ color: themeTextColor }} >How to end it politely</h3>
                            </div>
                            <div className='blog-text-style-block'>
                                <span className='blog-text-style' style={{ color: themeTextColor }}  >
                                    Not every match you talk to will end in happily ever after, and that’s okay. But ending a
                                    relationship – or what could have become a relationship – can be tricky. It may....
                                </span>
                            </div>
                            <div className='write-text-style'>
                                <p style={{ fontSize: 14, color: themeTextColor }} >Ilissa</p>
                            </div>
                        </div>
                    </div>
                </Link>

                <Link style={{ textDecoration: 'none' }} className="App-link" to="/can-you-meet-the-right-person-at-the-wrong-time">
                    <div className='div-contain' style={{ backgroundColor: themeBackgroundColor, }}>

                        <div>
                            <img src={couple1} alt="img" className='blog-image-cover'></img>
                        </div>
                        <div>
                            <div >
                                <h3 className='blog-title-style' style={{ color: themeTextColor }} >Can You Meet The Right Person At The Wrong Time?</h3>
                            </div>
                            <div className='blog-text-style-block'>
                                <span className='blog-text-style' style={{ color: themeTextColor }}  >
                                    I think, more often than not, when a man tells a woman  they've been dating casually for a period of time
                                    that they aren't  looking for a relationship, what they actually mean is, "I'm not  interested in....
                                </span>
                            </div>
                            <div className='write-text-style'>
                                <p style={{ fontSize: 14, color: themeTextColor }} >Christan Marashio</p>
                            </div>
                        </div>
                    </div>
                </Link>

                <Link style={{ textDecoration: 'none' }} className="App-link" to="/writing-the-best-online-dating-profile">
                    <div className='div-contain' style={{ backgroundColor: themeBackgroundColor, }}>

                        <div>
                            <img src={couple5} alt="img" className='blog-image-cover'></img>
                        </div>
                        <div>
                            <div >
                                <h3 className='blog-title-style' style={{ color: themeTextColor }} >Writing The Best Online Dating Profile.</h3>
                            </div>
                            <div className='blog-text-style-block'>
                                <span className='blog-text-style' style={{ color: themeTextColor }}  >
                                    Portraying yourself accurately in the online dating world means more than just being honest about age, height, and career.
                                    It means giving others a clear look into your personality and everyday life, not just....
                                </span>
                            </div>
                            <div className='write-text-style'>
                                <p style={{ fontSize: 14, color: themeTextColor }} >Lauren</p>
                            </div>
                        </div>
                    </div>
                </Link>

                <Link style={{ textDecoration: 'none' }} className="App-link" to="/signs-your-date-is-toxic">
                    <div className='div-contain' style={{ backgroundColor: themeBackgroundColor, }}>

                        <div>
                            <img src={couple10} alt="img" className='blog-image-cover'></img>
                        </div>
                        <div>
                            <div >
                                <h3 className='blog-title-style' style={{ color: themeTextColor }} >Signs Your Date Is Toxic</h3>
                            </div>
                            <div className='blog-text-style-block'>
                                <span className='blog-text-style' style={{ color: themeTextColor }}  >
                                    We'd been sitting at our corner two-top table for less than thirty minutes when it
                                    started. I could barely finish a sentence without him cutting me off or turning the
                                    conversation back....
                                </span>
                            </div>
                            <div className='write-text-style'>
                                <p style={{ fontSize: 14, color: themeTextColor }} >Christan Marashio</p>
                            </div>
                        </div>
                    </div>
                </Link>

                <Link style={{ textDecoration: 'none' }} className="App-link" to="/lets-get-you-to-the-first-date-faster">
                    <div className='div-contain' style={{ backgroundColor: themeBackgroundColor, }}>

                        <div>
                            <img src={couple7} alt="img" className='blog-image-cover'></img>
                        </div>
                        <div>
                            <div >
                                <h3 className='blog-title-style' style={{ color: themeTextColor }} >Let’s get you to the first date faster.</h3>
                            </div>
                            <div className='blog-text-style-block'>
                                <span className='blog-text-style' style={{ color: themeTextColor }}  >
                                    You can message back and forth all day long, but when it comes to an actual date, you
                                    have to put yourself out there. While the rom-coms make asking someone out....
                                </span>
                            </div>
                            <div className='write-text-style'>
                                <p style={{ fontSize: 14, color: themeTextColor }} >Sylvi</p>
                            </div>
                        </div>
                    </div>
                </Link>

            </div>

        </div >
    );
}

export default Advises;

/* const mapStateToProps = (state) => {

    // const blogs = Object.Values(state.Blogs.blogs)
    const blogs = state.Blogs.blogs
    //console.log(blogs)
    return { blogs };
};

export default connect(mapStateToProps, { BlogsFetch })(Advises); */
/*  <div className='div-container'>
                {this.props.blogs.map((item) => {
                    return (
                        <div key={item._id} className='div-contain'style={{ backgroundColor: themeBackgroundColor, }}>
                            <div>
                            <div>
                                <img src={item.image} alt="img" width='300' height="200"></img>
                            </div>
                            <div style={{ width: '300', height: "200" }}>
                                <h3 className='blog-title-style' >{item.title}</h3>
                            </div>
                            <div className='blog-text-style'style={{ color: themeTextColor }} >
                                <p >{item.text}</p>
                            </div>
                            <Link style={{ textDecoration: 'none' }} className="App-link" to={`/six/${item.title}`}>
                                <div>
                                    <p style={{ padding: 10,color: '#999' }} >1, March, 2020</p>
                                </div>
                            </Link>
                            </div>
                        </div>

                    )
                })
                }
 </div>
            </div > */
