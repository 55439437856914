import React, { useEffect,useState,useMemo } from 'react';
import '../../src/Styles.css';
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import couple from '../assets/screenshot_large_4.jpg';
import up from '../assets/up.png';
import HomeDrawer from '../Components/HomeDrawer';


const Blog_2 = () => {

    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])

const [themeTextColor, setTheme] = useState(null);

const isDark = undefined;
// Detecting the default theme
const systemPrefersDark = useMediaQuery(
  {
    query: "(prefers-color-scheme: dark)",
  },
  undefined
);


const value = useMemo(
  () => (isDark === undefined ? !!systemPrefersDark : isDark),
  [isDark, systemPrefersDark]
);

useEffect(() => {
  if (value) {
    setTheme('#fff')
  } else {
    setTheme('#111')
  }
}, [value]);

        return (

            <div >
                <div>
                    <HomeDrawer />
                </div>

                <div className='blog-div-container'>
                    <div className='blog-content'>


                    <Link style={{ textDecoration: 'none' }} className="App-link" to="/">
                    <div className="logo" ><h1 style={{ color: themeTextColor }}>Caribe Match</h1></div>
                </Link>

                        <h2 className='blog-title-header' style={{ color: themeTextColor }}>Things To Talk About When On a date.</h2>


                        <div style={{ marginTop: 12 }} >
                            <img src={couple} alt="Trulli" className='blog-image'></img>
                        </div>

                        <h3 className='blog-pargraph-autor' style={{ marginTop: 5, color: themeTextColor, }}>By Crystal Aav</h3>

                        <p className='blog-pargraph' style={{ color: themeTextColor }}>For many men, talking with their girlfriends is something that somehow seems natural.
                            In reality however, this is an art form that has to be mastered. No matter how close you
                            are to your girlfriend, there are times when your conversations suddenly run dry,
                            accompanied by awkward silence.

                            <h3 className='blog-pargraph-title1' style={{ marginTop: 15, color: themeTextColor, }}>1. DISCUSS YOUR FUTURE TOGETHER</h3>

                            This topic is top on the list of topics that must be handled with tact, especially if it is still very early on in the relationship. Coming on too fast may scare your girlfriend away and also not talking about it may raise some questions about commitment. We all know the one question that all men dread; so, where is this relationship going? If you want to avoid the anxiety associated with that question and its variants, this is a key area you should be talking about with your girlfriend. Talk about what you both think of marriage, settling down or kids, but do not focus too much on the intricate details. A good practice is to always be humorous about it. You do not want your girlfriend to get any drastic ideas and at the same time, you do not want her to feel that you are not committed. Talk about how your kids would be innocently disrupting your alone time or how it would be running around after the little rascals all over the house. Take her mind to the good aspects of parenting or what kind of parents you think you would be. This conversion will make you and your girlfriend feel more connected.
                            It can also allow you to gauge each other’s commitment levels and make you want to be in it for the long run.

                            <h3 className='blog-pargraph-title1' style={{ color: themeTextColor }}>2. WHAT YOU HAVE IN COMMON</h3>

                            It may be true that unlike poles attract, but in the world of relationships, having some things in common can be
                            leveraged for the betterment of the union. As a matter of fact, the things you have in common are the reasons why
                            you were attracted to each other in the first place. Therefore, you should regularly touch on this area. This
                            topic will always keep conversations going since common areas are the best connection points between two people. These may include old and new hobbies, annoying habits, fantasies, a book, movies, common values or beliefs and any similarities you may have in your physical appearance.

                            <h3 className='blog-pargraph-title1' style={{ color: themeTextColor }}> 3. WHAT YOU DO NOT HAVE IN COMMON</h3>

                            This is quite the reverse of the above point and it may seem counterproductive at first glance, but it is actually
                            very important. Talking about the differences between you and your girlfriend helps in learning to appreciate them. If you fail to address your differences, over time resentment because of them may build up and ruin the whole relationship. Sometimes, these differences could be the reason you were drawn together in a similar fashion to the things you have in common. A good thing about this topic is that it always keeps conversions going for hours on end. This is mainly because you will always find things that you do not agree on everywhere you look. You will both try to explain your perspectives on the issues and this makes up a good conversation. Just remember to always argue reasonably and learn to respect your girlfriend’s opinions even though you may not agree with them. She will love you for that. You may also find that over time you like her more because of your differences.


                            <h3 className='blog-pargraph-title1' style={{ color: themeTextColor }}>4. TALK ABOUT PAST EVENTS</h3>

                            In my opinion, one of the best conversations to have with your girlfriend is one that involves past events and
                            experiences. You can choose to talk about childhood experiences. This will definitely invoke some golden memories. Talk about your most embarrassing moments either in school or at home. Some of these experiences may have something to do with your current personality. Talk about something very wrong or illegal that you did and may or may not have gotten away with. You may find out that your girlfriend has a much crazier past than you and this is guaranteed to generate a few laughs. Bring up extremely funny experiences and events that make you crack up every time you think about them. Pop up a major past event or events that somehow shaped your life. Your girlfriend could also have been affected by some of the said events. You can also touch on the things that you did in the past that you are not exactly proud of. It is important to note that this conversation topic may open up a can of worms. Make sure that you are ready to hear anything your girlfriend may say.

                            <h3 className='blog-pargraph-title1' style={{ color: themeTextColor }}>5. CURRENT EVENTS</h3>

                            If you find yourself in a conversation with your girlfriend that is running dry, a good solution is looking no
                            further that that exact time. You can always opt for something simple to get the conversation going such as your
                            immediate surroundings. If you are outdoors, you can start by talking about the weather. Mention how you love it
                            or hate it, eventually bringing up activities you prefer doing in various weather conditions such as swimming,
                            ice skating, hiking or relaxing indoors. This will lead up to a conversation about your hobbies. Inquire about
                            what your girlfriend likes doing during her free time and her current interests. Also, you can talk about
                            current influential events that are happening around the world in areas including science, technology,
                            religion, politics and the arts.


                            <h3 className='blog-pargraph-title1' style={{ color: themeTextColor }}>6. WHEN BOTH OF YOU FEEL HAPPIEST</h3>

                            You can start by posing the above question in regards to your girlfriend. This question actually serves two
                            purposes. It will help you learn a lot more about your girlfriend and vice versa. It will also help you in
                            gaining insight into yourself in the process. Go on by talking about what exactly you enjoy doing either alone
                            or together. Discuss the reasons why you enjoy doing these things. You may come to realize that you are both
                            not doing things that make you happier and bring you closer more often. Your girlfriend may reform her
                            lifestyle to include more activities that make her happy. Trust me; it is always a win-win situation when
                            your girl is happy. When she feels better about herself, it will definitely reflect on how she feels about
                            you. One simple trick when you run out of options is highlighting something that your girlfriend is wearing
                            and inviting her to comment on it. Then sit back and watch it work wonders .It is
                            essential that you are able to recognize some of the most telltale signs that you
                            are dating a womanizer so you can get out before any real damage is done.
                            Sometimes it can be difficult to tell if you are with someone who really isn’t
                            good for you, which is why this information is so important to have.</p>
                    </div>
                </div>
            </div>

        );
}


export default Blog_2;
